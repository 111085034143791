export default function PricingCenter(ajax, config) {
  return {
    calendar: (opt) => ajax({ url: '/product-price/calendar', method: 'POST', ...opt }),
    calendarResource: (opt) => ajax({ url: '/resource-price/calendar', method: 'POST', ...opt }),
    resourceVendorRuleAgg: (opt) =>
      ajax({ url: '/resource-vendor-rule-agg', method: 'POST', ...opt }),
    resourceRule: {
      list: (opt) => ajax({ url: '/resource-rule/page', method: 'get', ...opt }),
      ruleList: (opt) => ajax({ url: '/resource-rule/list', method: 'get', ...opt }),
      add: (opt) => ajax({ url: '/resource-rule/add', method: 'post', ...opt }),
      info: (opt) => ajax({ url: `/resource-rule/${opt.c_id}`, method: 'get', ...opt }),
      edit: (opt) => ajax({ url: `/resource-rule/update`, method: 'POST', ...opt }),
      // 上下架废弃
      // status: (opt) => ajax({ url: `/resource-rule/update-status`, method: 'POST', ...opt }),
      log: (opt) => ajax({ url: `/operation-log/list`, method: 'get', ...opt }),
      // 停用
      disable: (opt) => ajax({ url: `/resource-rule/disable`, method: 'POST', ...opt }),
      // 启用
      enable: (opt) => ajax({ url: `/resource-rule/enable`, method: 'POST', ...opt }),
    },
    resourceDate: {
      // 价格详情
      detail: (opt) => ajax({ url: `/resource-date-rule/detail`, method: 'get', ...opt }),
      batchEditPrice: (opt) =>
        ajax({ url: `/resource-date-rule/batchUpdate`, method: 'post', ...opt }),
      // 创建加价规则
      createDefaultRule: (opt) =>
        ajax({ url: `/resource-date-rule/default/batchCreate`, method: 'put', ...opt }),
      //获取加价规则
      getDefaultRule: (opt) => ajax({ url: `/resource-date-rule/default`, method: 'get', ...opt }),
    },
    product: {
      cityList: (opt) => ajax({ url: `/product/departure-city`, method: 'get', ...opt }),
      close: (opt) => ajax({ url: `/product/close-dep-date`, method: 'post', ...opt }),
      open: (opt) => ajax({ url: `/product/open-dep-date`, method: 'post', ...opt }),
      // 产品可售团期
      availableForSaleGroup: (opt) =>
        ajax({ url: `/product/departure-dates/${opt.productId}`, method: 'GET', ...opt }),
    },
    resource: {
      // 资源可售团期
      availableForSaleGroup: (opt) =>
        ajax({ url: `/resource/departure-dates/${opt.resourceId}`, method: 'GET', ...opt }),
    },
  }
}
