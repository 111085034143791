export default {
  批量加价: '批量加價',
  设置加价规则: '設置加價規則',
  同行价: '同行價',
  指定客商等级同行价: '指定客商等級同行價',
  指定客商同行价: '指定客商同行價',
  等级: '等级',
  绑定产品: '綁定產品',
  确定绑定: '確定綁定',
  同行价类型: '同行價類型',
  请先勾选产品: '請先勾選產品',
  绑定成功: '綁定成功',
  同行价只能设置一条: '同行價只能設置一條',
  指定客商等级同行价只能设置一条: '指定客商等級同行價只能設置一條',
  同一客商只能设置一条: '同一客商只能設置一條',
  请设置同行价加价规则: '請設置同行價加價規則',
}
