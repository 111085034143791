export default {
  委托订票单: '委託訂票單',
  基本信息: '基本信息',
  交易号码: '交易號碼',
  电子单据: '電子單據',
  客户: '客户',
  交易时间: '交易時間',
  外部订单号: '外部訂單號',
  交易日期: '交易日期',
  联络人中文名: '聯絡人中文名',
  联络人英文名: '聯絡人英文名',
  联络人姓名: '聯絡人姓名',
  联络人电邮: '聯絡人電郵',
  联络人电话: '聯絡人電話',
  联络人传真: '聯絡人傳真',
  销售员: '銷售員',
  销售员电邮: '銷售員電郵',
  销售员电话: '銷售員電話',
  销售员传真: '銷售員傳真',
  单据模板: '單據模板',
  付款方式: '付款方式',
  付款说明: '付款說明',
  客户回复期限: '客戶回復期限',
  客户回复期限提示: '請聯系{0}，確認客戶回復期限前可給客戶保留位子',
  订单备注: '訂單備註',
  订购须知: '訂購須知',
  签字盖章件: '簽字蓋章件',
  上传签字盖章件: '上傳簽字蓋章件',
  生成时间: '生成時間',
  打印次数: '列印次數',
  发送次数: '發送次數',
  保存并生成单据: '保存並生成單據',
  请选择单据模板: '請選擇單據模板',
  请上传签字盖章件: '請上傳簽字蓋章件',
  我方模板: '我方模板',
  对方模板: '對方模板',
  保存并生成单据成功: '保存並生成單據成功',
  订购须知默认:
    '<ul><li>請於{0}前確認本「委託訂票單」及辦理付款手續，如果未能在指定時間内回覆或未完成付款程序，本公司將不會為閣下提供訂票、送票服務，並保留取消此訂單之權利。</li><li>如受委託的訂票未能安排，所繳付之費用將全數退回。</li><li>若因時差問題，上述票款出現偏差，本公司將保留調整之權利。</li><li>在訂單已確認及作實後，如要更改或取消，須根據各產品的改期或退票條款處理，請查閱中旅社網網上所列各產品的「乘運條例」、「訂購細則」内容。</li><li>以上款項均以港幣計算。</li><li>送件時間：</li></ul><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11:00前確認，送件時間為當天14:00至18:00。</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11:00後至17:00前確認，送件時間為翌日9:00至13:00。</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如送票時段是星期六下午、星期日及公眾假期，送票時段會順延至該日期之後的第一個工作天的9：00至13：00</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如需更改送件時間，請於確認時通知我司。</p><ul><li>{1}</li><li>旅客於出發之前務必檢查證件及簽證之有效日期，以免造成延誤，影響行程！</li></ul>',
  改签: '改签',
  确认改签: '確認改签',
  重新提交改签申请: '重新提交改簽申請',
  chooseTicket: '第1步 | 選擇需改簽車票',
  chooseClass: '第2步 | 選擇改簽班次',
  submit: '第3步 | 提交改簽申請',
  取消改签: '取消改签',
  改签前: '改簽前',
  改签后: '改簽后',
  申请改签: '申請改簽',
  chosenTip: '已選擇 {0} 張改簽車票',
  chooseTip: '同班次多張車票可一起改簽',

  jumpStepOneBtn: '變更需改簽車票',
  jumpStepTwoBtn: '變更改簽班次',
  // 输入需改签车票号，同班次多张票用‘，’隔开’
  certPlaceholder: '輸入需改簽車票號，同班次多張票用“，”隔開',
  nextStep: '下一步',
  prevStep: '上一步',
  noResource: '請先選擇改簽車票',
  // 点击【提交】后，请联系{产品负责人}处理核损单，再根据核损结果进行后续处理’
  submitTips: '提交後，請聯繫{0}處理核损單，再根據核損結果進行後續處理',
  // 如有改签应收，请先完成全额收款，收款后，点击【确认】正式向供应商发起改签
  confirmRebookTips: '如需改簽，請先完成全額收款，收款後，點擊【確認】正式向供應商發起改簽',
  改签应收总额: ' 改簽應收總額',
  改签应收: ' 改簽應收：',
  发票号码: '發票號碼',
  收款原币金额: '收款原幣金額',
  本次对账原币金额: '本次對賬原幣金額',
  合计原币金额: '合計原幣金額',
  CC客户: 'CC客戶',
  往返票无某一程票价: '往返票無某一程票價',
  改签应收以实际核损为准: '改簽應收 以實際核損為準',
  手续费待核损: '手續費待核損',
  票价: '票價',
  差价: '差價',
  手续费: '手續費',
}
