export default {
  T: '是',
  F: '否',
  resourceType: {
    FLIGHT: '散客機票',
    HOTEL: '酒店',
    TOUR: '旅行團',
    HOTEL_PACKAGES: '酒店套餐',
    TEAM_FIGHT: '團隊機票',
    TICKET: '門票',
    TICKET_PACKAGES: '門券',
    VISA: '簽證',
    AUTHENTICATION: '認證',
    LOCAL_SERVICES: '當地服務',
    TOUR_GUIDE: '導遊',
    CUSTOMS_CLEARANCE: '過關服務',
    VOUCHER_CARD: '劵卡',
    STEAMER_TICKET: '船票',
    CHARTERED_BUS: '包車',
    USE_THE_CAR: '租車',
    BUS: '巴士',
  },
  SourceEnum: {
    1: 'NBS',
    2: '直連',
    3: '供',
  },
  CONTAIN_RETURN_TRAFFIC: {
    1: '不包含大交通',
    2: '包含大交通',
  },
  auditStatus: {
    1: '待提交',
    2: '審批中',
    3: '已通過',
    4: '已駁回',
  },
  isTraffic: {
    1: '不包含往返交通',
    2: '包含往返交通',
  },
  RoundTripTraffic: {
    1: '飛機',
    2: '火車軟臥',
    3: '火車硬臥',
    4: '火車軟座',
    5: '火車硬座',
    6: '汽車',
    7: '郵輪',
    8: '火車',
    9: '動車組',
    10: '遊船',
    11: '高鐵二等座',
    12: '高鐵一等座',
    13: '高鐵商務座',
  },
  stockType: {
    StockTypeF: '庫存',
    StockTypeT: '散客',
  },
  PricingMethodEnum: {
    1: '按人計價',
    2: '按套計價',
  },
  TripTypeEnum: {
    1: '單程',
    2: '往返',
    3: '多程',
  },
  PricingUnitEnum: {
    1: '位',
    2: '套',
    3: '張',
    4: '次',
    5: '間',
  },
  TicketTypeEnum: {
    1: '成人',
    2: '小童',
    3: '長者',
    4: '不限',
  },
}
