const basePath = '/order-sys/'

export default [
  // {
  //   path: basePath + 'order-collection',
  //   name: basePath + 'order-collection',
  //   component: () => import('@/views/order/collection/index.vue'),
  //   meta: { title: '订单收款列表' },
  // },
  // {
  //   path: basePath + 'order-collection-add',
  //   name: basePath + 'order-collection-add',
  //   component: () => import('@/views/order/collection/addCollection/index.vue'),
  //   meta: { title: '添加收款' },
  // },
  // {
  //   path: basePath + 'order-collection-edit',
  //   name: basePath + 'order-collection-edit',
  //   component: () => import('@/views/order/collection/addCollection/index.vue'),
  //   meta: { title: '编辑收款' },
  // },
  // {
  //   path: basePath + 'order-collection-info',
  //   name: basePath + 'order-collection-info',
  //   component: () => import('@/views/order/collection/addCollection/index.vue'),
  //   meta: { title: '收款详情查看' },
  // },
  {
    path: basePath + 'order-intention',
    name: basePath + 'order-intention',
    component: () => import('@/views/orderSys/intention/index.vue'),
    meta: { title: '意向单列表' },
  },
  {
    path: basePath + 'receive-order',
    name: basePath + 'receive-order',
    component: () => import('@/views/orderSys/order/receiveOrder/index.vue'),
    meta: { title: '接单页' },
  },
  {
    path: basePath + 'order-list',
    name: basePath + 'order-list',
    component: () => import('@/views/orderSys/order/list/index.vue'),
    meta: { title: '订单列表' },
  },
  {
    path: basePath + 'order-details',
    name: basePath + 'order-details',
    component: () => import('@/views/orderSys/order/orderDetail'),
    meta: { title: '订单详情' },
  },
  // {
  //   path: basePath + 'batch-order',
  //   name: basePath + 'batch-order',
  //   component: () => import('@/views/order/batch-order'),
  //   meta: { title: '批量下单' },
  // },
  // {
  //   path: basePath + 'batch-order-result',
  //   name: basePath + 'batch-order-result',
  //   component: () => import('@/views/order/batch-order/result'),
  //   meta: { title: '批量下单' },
  // },
  // {
  //   path: basePath + 'batch-order-history',
  //   name: basePath + 'batch-order-history',
  //   component: () => import('@/views/order/batch-order/history'),
  //   meta: { title: '批量下单' },
  // },
  // {
  //   path: basePath + 'appoint-tool',
  //   name: basePath + 'appoint-tool',
  //   component: () => import('@/views/order/appoint-tool/index.vue'),
  //   meta: { title: '预约工具' },
  // },
  {
    path: basePath + 'commission-sales',
    name: basePath + 'commission-sales',
    component: () => import('@/views/order/commission-sales/index.vue'),
    meta: { title: '销售佣金查看' },
  },
  {
    path: basePath + 'config/contract-collect',
    name: basePath + 'config/contract-collect',
    component: () => import('@/views/orderSys/config/contractcollect/index.vue'),
    meta: { title: '订单签约收款配置' },
  },
  {
    path: basePath + 'entrusted-ticket',
    name: basePath + 'entrusted-ticket',
    component: () => import('@/views/orderSys/order/EntrustedTicket'),
    meta: { title: '委托订票单', auth: false },
  },
  {
    path: basePath + 'customer-reconciliation',
    name: basePath + 'customer-reconciliation',
    component: () => import('@/views/orderSys/customerReconciliation/list/index.vue'),
    meta: { title: '客户对账' },
  },
  {
    path: basePath + 'customer-supplier-detail',
    name: basePath + 'customer-supplier-detail',
    component: () => import('@/views/orderSys/customerReconciliation/list/supplierDetail/index'),
    meta: { title: '对账单详情' },
  },
  {
    path: basePath + 'customer-reconciliation-collection',
    name: basePath + 'customer-reconciliation-collection',
    component: () => import('@/views/orderSys/customerReconciliation/collection/index.vue'),
    meta: { title: '收款单详情' }, // 收款操作页
  },
  {
    path: basePath + 'customer-reconciliation-detail',
    name: basePath + 'customer-reconciliation-detail',
    component: () => import('@/views/orderSys/customerReconciliation/collection/index.vue'),
    meta: { title: '收款单详情' }, // 收款查看页
  },
]
