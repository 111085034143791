export default function MemberServer(ajax, config) {
  return {
    //会员码列表
    queryMemberList: (opt) =>
      ajax({
        url: `/member/code-list`,
        method: 'POST',
        ...opt,
      }),

    //会员列表
    memberList: (opt) =>
      ajax({
        url: `/member/list`,
        method: 'POST',
        ...opt,
      }),

    // 发送邮件
    invitationEmail: (opt) =>
      ajax({
        url: `/send-invitation-email`,
        method: 'POST',
        ...opt,
      }),

    // 会员常用旅客列表查询
    queryMemberTourList: (opt) =>
      ajax({
        url: '/member-tourist/list',
        method: 'POST',
        ...opt,
      }),

    // 查询会员资产信息
    memberProperty: (opt) =>
      ajax({
        url: `/member/property`,
        method: 'POST',
        ...opt,
      }),
  }
}
