export default {
  offlinePayment: '非線上支付',
  onlinePayment: '線上支付',
  toBeRefunded: '待退款',
  refunded: '已退款',
  refundFailed: '退款失敗',
  refundInProgress: '退款中',
  toBeCollected: '待收款',
  collected: '已收款',
  onlinePaymentWeChat: '線上支付-微信',
  bankTransfer: '銀行轉賬',
  retrace: '原路退回',
  refundToAdvanceCollection: '退款轉預收',
  bank: '銀行',
  travelExpense: '旅費',
  notCarriedForward: '未結轉',
  carriedOver: '已結轉',
  sharedRejection: '共享駁回',
  shareReviewed: '共享已審核',
  pendingReview: '待審核',
  reviewed: '已審核未結轉',
  rejected: '已駁回',
  voided: '已作廢',
  transferAgainMsg: '不退回訂單，重新結轉共享',
  returnOrder: '退回訂單重新發起',
  nonDetectedAccount: '非待查戶',
  detectedAccount: '待查戶',
  directSaleStore: '直營店',
  innovationStore: '創新店',
  baseInfo: '基本信息',
  attachmentInfo: '附件信息',
  businessInfo: '業務明細',
  transfer: '結轉',
  batchTransfer: '批量結轉',
  batchReview: '批量審核',
  batchSettlement: '批量結算',
  batchReviewSuccess: '批量審核成功',
  batchSettlementSuccess: '批量結算成功',
  submitSuccess: '提交成功',
  confirmButtonText: '通過',
  reviewReject: '審核駁回',
  reviewPass: '審核通過',
  downloadDetail: '導出明細',
  handle: '處理',
  modify: '修改',
  receipt: '收款',
  print: '列印',
  delete: '删除',
  deleteTip: '確定刪除此對賬單',
  remove: '移除',
  returnPayment: '退款',
  download: '下載',
  downloadFile: '附件下載',
  paymentReviewVerify: '應付單審核',
  prePayVerify: '預付款審核',
  review: '審核',
  notEmpty: '暫無數據',
  inputProductCompanyName: '請輸入產品公司名稱',
  inputProductId: '請輸入產品碼',
  inputTeamName: '請輸入團名稱',
  settlementTypeIncome: '收入',
  settlementTypeCost: '成本',
  settlementMiniTitle: '收入&成本結轉明細',
  smallSums: '小計',
  total: '總',
  selectMonth: '請選擇月份',
  editRules: '編輯規則',
  typeName: '類型',
  exportExcel: '導出EXCEL',
  exportExcelSuccess: '導出Excel成功。',
  amountLimit: '請輸入小於99999999.99的數字',
  toBeSubmitted: '待提交',
  financeReviewPassed: '財務審核通過',
  financeReviewRejected: '財務審核駁回',
  config: {
    NBSCompanyId: 'NBS公司ID',
    NBSCompany: 'NBS公司',
    NBSApartmentId: 'NBS部門ID',
    NBSApartment: 'NBS部門',
    shareApartemntId: '共享部門ID',
    shareApartemnt: '共享部門',
    shareCompanyId: '共享公司ID',
    shareCompany: '共享公司',
    delInfo: '此操作將删除部處關聯，是否繼續？',
    NBSCompanyIdRuleTips: 'NBS公司ID必須為數字',
    NBSApartmentIdRuleTips: 'NBS部門ID必須為數字',
    plsEnterNumber: '請輸入數字',
    plsEnterNBSCompanyId: 'NBS公司ID必填',
    plsEnterNBSCompanyName: 'NBS公司名稱必填',
    plsEnterNBSApartmentId: 'NBS部門ID必填',
    plsEnterNBSApartmentName: 'NBS部門名稱必填',
    plsEnterShareCompanyId: '共享公司ID必填',
    plsEnterShareCompanyName: '共享公司名稱必填',
    plsEnterShareApartemntId: '共享部門ID必填',
    plsEnterShareApartemntName: '共享部門名稱必填',
    delTaxRateLinkInfo: '此操作將删除稅率關聯，是否繼續？',
    plsChooseType: '請選擇類型',
    taxesType: '稅收類型',
    plsChooseTaxesType: '請選擇稅收類型',
    plsEnterTypeName: '類型必填',
    plsEnterTaxRateTypeName: '稅收類型必填',
    plsEnterCarryOverTypeName: '結轉類型必填',
    salesTax: '銷項稅',
    incomeTax: '進項稅',
    plsEnterCompanyName: '公司名稱必填',
    plsEnterGroupTicketTypeName: '團發票類型必選',
    plsChooseCarryOverTypeName: '結轉類型必選',
    plsEnterTaxRate: '稅率必填',
    carryOverTypeName: '結轉類型',
    groupTicketTypeName: '團發票類型',
    taxRate: '稅率',
    businessType: '業務類型',
    businessTypeName: '業務類型名稱',
    groupTypeName: '團類型名稱',
    ticketType: '發票類型',
    ticketTypeName: '發票類型名稱',
    singleOrderType: '單項委託類型',
    singleOrderTypeName: '單項委託類型名稱',
    SAPGroupCategory: 'SAP團類別',
    SAPGroupCategoryName: 'SAP團類別名稱',
    delGroupTypeInfo: '此操作將删除團類型，是否繼續？',
    /* 币种信息管理 Start */
    manageCurrencyInfo: '幣種信息管理',
    notSpecifyCurrency: '非指定幣種',
    specifyCurrency: '指定幣種',
    currencySymbol: '幣種代號',
    notNull: '不能為空',
    currencyUnit: '幣種組織',
    currencyType: '幣種類型',
    currencyName: '幣種名稱',
    currencyAbbreviationSymbol: '幣種縮寫符號',
    delCurrencyConfirm: '此操作將删除幣種配寘資訊，是否繼續？',
    /* 币种信息管理 End */
    /* 币种汇率管理 Start */
    manageCurrencyExchangeRate: '幣種匯率管理',
    dateOfApplication: '適用日期',
    exchangeRateTip:
      '源幣種* 中間價=目的幣種，本匯率錶組織為1源幣換算目的幣種，即1源幣折合多少目的幣種。',
    exchangeRateMiddlePrice: '匯率（中間價）',
    currencyTip: '源幣種和目的幣種不能相同',
    approximatelyEqualTo: '約等於',
    sourceCurrency: '源幣種',
    destinationCurrency: '目的幣種',
    historyExchangeRateMsg: '歷史匯率不會更新',
    maintainExchangeRate: '維護匯率',
    month: '月份',
    validateRateMsg1: '請輸入大於0的數',
    validateRateMsg2: '輸入最多8比特小數',
    validateRateMsg3: '請輸入匯率，最多8比特小數',
    importRule: '導入規則',
    importTip1: '1、請先下載範本，在範本中填寫需要導入的資訊，然後上傳該文件。',
    importTip2: '2、導入匯率只會生成/更新當天及未來匯率，歷史日期匯率不會更新。',
    importTip3: '3、按月導入，如源幣種+目的幣種+適用月份多條重複，以最後一條為准。',
    importTip4:
      '4、格式示例：源幣：美元 目的幣種：人民幣 匯率：7.0998適用月份：2022-12格式錯誤則無法成功導入。',
    importTip5:
      '5、源幣種* 中間價=目的幣種，本匯率錶組織為1源幣換算目的幣種，即1源幣折合多少目的幣種。',
    importFile: '導入文件',
    clickToUpload: '點擊上傳',
    supportFormat: '支持拓展名：.xls .xlsx',
    downloadTemp: '範本下載',
    import: '導入',
    importExchangeRageSuccess: '匯率導入成功！',
    uploadTip1: '請先點擊上傳要導入的文件',
    uploadTip2: '上傳文件大小不能超過50MB',
    /* 币种汇率管理 End */
  },
  settlement: {
    pageLoading: '頁面加載中',
    manualSettlement: '手動結算',
    manualSettlementMsg: '確定後，將會生成‘未提交’狀態的毛利審批申請，請及時提交毛利審批',
    manualSettlementWarning:
      '此團存在訂單實收大於應收，請聯系訂單銷售人員，確認訂單款項是否需調整。 否則，後續需多次補充結算，是否繼續？',
    manualSettlementConfirmMsg: '確定手動結算嗎？',
    settlementTip: '結算提示：',
    settlementSuccess: '結算成功',
    transferTeamArchive: '結轉團檔案',
    transferTeamArchiveMsg: '確定後，該團檔案信息將會結轉到財務共享系統，請關注結轉結果。',
    transferTeamArchiveConfirmMsg: '確定結轉團檔案嗎？',
    transferLoading: '結轉團檔案中',
    orderDetail: '訂單明細',
    orderDetailTips:
      '當前團是按照銷售端毛利計算傭金，首次結算前，編輯‘公司內部結算價’會更新傭金，首次結算時，鎖定傭金金額，如您需要調整，請務必在首次結算前完成。',
    confirmDetail: '確認單明細',
    removeOrderSuccess: '移除訂單操作成功',
    joinInnovation: '加盟創新',
    removeOrderConfirmMsg: '是否確定移除訂單？',
    removeOrder: '移除訂單',
    plsChooseOrderToRemove: '請選擇需要移除的訂單',
    grossProfitInfo: '毛利信息',
    resourceDetailList: '資源明細',
    additionalList: '其他附加項明細',
    explain: '說明',
    plsEnterInnerSettlePrice: '請輸入內部結算價',
    validateMsg1: '請輸入≥0數字',
    validateMsg2: '最多兩位小數',
    checkMsg: '公司內部結算價匯總要小於訂單總價',
    editSuccess: '編輯成功',
    incomeItem: '收入項',
    commissionDetail: '傭金明細',
    reminderTips: '請根據結轉單號，在共享草稿及時提交，完成共享流程',
    canNotSettleTip: '該團存在未出遊歸來的訂單，暫時不可結算，點詳情查看。',
    theNumber: '第',
    frequency: '次',
    dealMsg: '您不是該毛利審批的結算人，不可操作',
    pendingApproval: '待審核',
    approved: '已審核',
    approval: '審批',
    grossProfitApproval: '毛利審批申請',
    plsFillIn: '請填寫',
    carryOverDetail: '收入成本結轉明細',
    approvalRecord: '審批記錄',
    auditRemark: '審批意見',
    reject: '駁回',
    pass: '同意',
    validateRemarkMsg: '備註不能全是空格',
    validateAuditRemarkMsg: '審批意見不能全是空格',
    cancelTip: '確定後，將會作廢該條毛利審批數據，後續需在‘團檔案’功能重新發起結算。',
    cancelConfirmMsg: '確定作廢毛利審批申請嗎？',
    approvalSuccess: '審批成功',
    currentIncomeDetail: '本次收入明細',
    currentCostDetail: '本次成本明細',
    approvalConfirm: '確定對選中的單據進行批量審核通過操作嗎？',
  },
  receivePay: {
    carryForwardReceiptMsg: '請及時完成收款單結轉',
    reCarryingReminderMsg: '請查看操作記錄，如有問題聯系技術支援，重新結轉',
    carryForwardReminderMsg: '請及時完成退款單結轉',
    adult: '大',
    child: '小',
    infant: '嬰',
    handleRefund: '退款處理',
    confirmPushMsg: '確認勾選的退款推送財務共享進行審核',
    batchTransferMsg:
      '同一批次審核結轉的退款記錄必須客戶相同、幣種相同、退款類型相同、收款方式相同，請檢查',
    singleTransferConfirmMsg: '此操作將重新結轉退款匯總單到共享草稿，請確認是否繼續',
    invalidConfirmMsg: '此操作將作廢退款單，您需要返回訂單清單，重新對訂單選擇退款方式',
    invalidSuccessMsg: '退款單已作廢，請稍後在訂單中重新選擇退款方式',
    refundInfo: '退款信息',
    plsEnterHandlingFee: '請填寫手續費',
    validateHandlingFeeMsg1: '請填寫大於等於0的數值，最多兩位小數',
    validateHandlingFeeMsg2: '請填寫小於等於|的數值',
    handlingFeeSuccessMsg: '手續費修改成功，退款已更新',
    createPreReceipt: '創建預收單',
    preReceipt: '預收款',
    preReceiptRefund: '預收款退款',
    bankName: '收款銀行',
    pleaseEnterAmount: '請輸入金額',
    individualTraveler: '散客',
    createSuccess: '創建成功',
    edit: '修改',
    editSuccess: '修改成功',
    validateFlowAmountMsg: '收款金額和水單認領金額總和不相等，請檢查確認',
    collectionInfo: '收款信息',
    claim: '認領',
    cancelClaim: '取消認領',
    none: '無',
    salesman: '銷售人員',
    pleaseEnterTransferReason: '請輸入轉賬原因',
    transferInInfo: '轉入信息',
    transferOutInfo: '轉出信息',
    transferSuccess: '轉出成功',
    validateTransferAmountMsg: '轉入金額應小於等於剩餘金額',
    personal: '個人',
    enterprise: '企業',
    preReceiptRefundMsg: '退款金額不能大於預收款剩餘未開票金額',
    validateBackAmountMsg: '退款金額不能大於剩餘金額',
    refundSuccess: '退款成功',
    useRecord: '使用記錄',
    delConfirmMsg: '確認將此預收單删除，删除後無法恢復',
    validateLeftAmountMsg: '剩餘金額必須大於0',
    orderTeamTransfer: '訂單轉團調賬',
    toDo: '待我處理',
    processed: '已處理',
    startTransfer: '發起調賬',
    transferDetail: '調賬詳情',
    transferDetails: '調賬明細',
    transferConfirmMsg: '此操作將重新結轉收款匯總單到共享草稿，請確認是否繼續',
    transferSuccessTip: '單據已推送共享，請稍後在共享介面進行查看',
  },
  deposit: {
    prepaidUseInfo: '押金使用記錄',
    departmentLimitInfo: '請保證押金創建部門與當前使用部門在同一個部門內',
    pleaseSelectDepositInfo: '請勾選押金數據後再點擊確定',
    exchangeRateMsg1: '請輸入正確格式的匯率，最多輸入8位小數',
    exchangeRateMsg2: '最多輸入8位小數',
    exchangeRateMsg3: '當前最新匯率為空，請聯系財務維護該幣種的最新匯率',
    finExchangeRate: '財務設定匯率',
    than: '比',
    higher: '高',
    lower: '低',
  },
  upload: {
    promptInformation: '滑鼠點擊此處可以黏貼文件上傳',
    formatNotPreview: '當前格式不支持預覽！',
    preview: '預覽',
  },
  relatedParty: {
    unsettlement: '結算處理',
    settlementProcessing: '結算確認',
    carryOverProcessing: '結轉處理',
    incomeDetail: '收入明細',
    costDetail: '成本明細',
    relatedPartyDetail: '關聯交易明細',
    selectTeamDate: '請選擇可銷售日期',
    selectSettlementAuditTime: '請選擇成本結轉審核月份',
    confirmSettlementInfo: '當前未結算數據將會生成結算單據，是否生成。',
    inputSettlementCode: '請輸入結算單號',
    comfirmSettlementInfo: '當前結算單的結算單狀態將更新為已確認，是否繼續？',
    comfirmSettlementAbandon: '當前結算單將作廢，作廢後結算單狀態為已作廢，是否繼續？',
    comfirmSettlementAudit: '當前結算單狀態將更新為已審核，是否繼續？',
    comfirmSendFinancial: '當前結轉明細將推送到財務共享系統，是否繼續？',
    inputCompanyName: '請輸入公司名稱',
    inputCompanySettlementCode: '請輸入成本結轉單號',
    selectCompanyId: '請先選擇公司',
    selectMultipleSelection: '請勾選數據',
    comfirmAudit: '確認勾選的收款推送財務共享進行審核',
    msgShareDataSuccess: '單據已推送共享，請稍後在共享介面進行查看',
    msgShareDataError:
      '同一批次審核結轉的收款記錄必須客戶相同、幣種相同、收款類型相同、收款方式相同，請檢查',
    searchNotData: '搜索無結果請重新搜索',
    inputCustomerName: '請輸入客戶名稱',
    inputReceiptSettlementCode: '請輸入收入結轉單號',
    receiptsCollection: '錄入收款',
    selectCollectionDepartment: '請先選擇申請收款部門',
    selectValidDate: '選擇月份',
    relatedPartyAddRule: '添加規則',
    confirmStopRule: '確定停用該規則？',
    confirmOpenRule: '確定啟用該規則？',
    confirmButtonStop: '確定停用',
    confirmButtonOpen: '確定啟用',
    manualCollectionEntry: '手工收款錄入',
    inputCollectionAmount: '請填寫收款金額',
    errorCollectionAmount: '收款金額不能大於待收金額',
    selectCollectionBank: '請選擇收款銀行',
    selectCurrencyCode: '請選擇幣種',
    selectHandlingFeeRate: '請選擇手續費率',
    selectFlowClaimDetailList: '請選擇支付水單編號',
    receiptsTypeGroup: '團款',
    receiptsTypeItems: '附加項',
    unCapped: '未封頂',
    capped: '已封頂',
    inputFlowId: '請輸入水單編號',
    inputPayName: '請輸入匯款人信息',
    inputTerminalNo: '請輸入終端號',
    selectCollectionBankCode: '請選擇收款銀行賬號',
    selectFlagEnumList: '請選擇待查戶',
    yes: '是',
    no: '否',
    selectFlowDate: '請選擇水單日期',
    inputClaimAmount: '請輸入認領金額',
    msgReceiptAmount: '認領金額與收款金額不相等',
    settlementInfo: '收入結轉單信息',
    settlementButtonDetail: '結轉明細',
    tax: '（含稅）',
    notTax: '（不含稅）',
    order: '訂單',
    company: '公司',
    companyValue: '中國旅遊集團旅行服務有限公司散客',
    inputProductCompanyProfit: '請填寫產品公司利潤占比',
    inputPurchaseCompanyProfitRatio: '請填寫採購公司利潤占比',
    inputSalesCompanyProfitRatio: '請填寫銷售公司利潤占比',
    inputNumberInteger: '請填寫0-100的整數',
    msgProfitRatio: '利潤占比不等於100%，請修改。',
    showRules: '查看規則',
    formatDate: 'yyyy年MM月',
  },
  payment: {
    unreconciledStatement: '未對賬',
    supplierReconciliationQuery: '對賬查詢',
    placeholderCompanyName: '請輸入採購公司',
    placeholderTeamNo: '請輸入團代號',
    placeholderOrderId: '請輸入訂單號',
    placeholderVendorName: '請輸入供應商名稱',
    placeholderSubPaymentCode: '請輸入應付單號',
    selectStatus: '請選擇審核狀態',
    triggerTime: '創建時間|申請時間',
    noSelectData: '未選擇數據',
    confirmErrorInfo: '不能輸入大於2147483647的數字',
    vendor: '供應商',
    departmentName: '申請部門',
    billCompareCode: '對賬單號',
    placeholderBillCompareCode: '請輸入對賬單號',
    operatorName: '對賬操作人',
    compareBillStatus: '對賬狀態',
    importBill: '上傳賬單',
    teamSettlementStatus: '團結算狀態',
    selectTeamSettlementStatus: '請選擇團結算狀態',
    updateUserId: '審核人',
    payApply: '付款申請',
    placeholderPaymentCode: '請輸入付款匯總單號',
    guideInfo: '導遊領隊信息',
    abandonedSuccess: '作廢成功',
    payStatus: '付款狀態',
    limitNumInput: '請輸入金額，最多兩位小數',
    largeLimitNum: '請輸入大於0的數字',
    addReturnInfo: '添加退款',
    selectPaymentNoEmpty: '選擇的數據本次付款金額不能為空',
    selectPayment: '請選擇付款單',
    largeNumWriteOff: '請輸入大於或等於待核銷原幣金額',
    smallNumWriteOff: '請輸入小於或等於待核銷原幣金額',
    payModeCode: '付款方式',
    selectPayModeCode: '請選擇付款方式',
    placeholderBankName: '請選擇開戶行',
    placeholderBusinessId: '請輸入業務單據號',
    createTeamUser: '建團人',
    largeLimit: '請輸入大於或等於',
    smallLimit: '請輸入小於或等於',
    paymentVoucherType: '應付單據類型',
    selectPaymentVoucherType: '請選擇應付單據類型',
    businessType: '業務單據類型',
    selectBusinessType: '請選擇業務單據類型',
    departmentPersonName: '申請人部門',
    placeholderDepartmentName: '請輸入申請人部門',
    confirmName: '確定對選中的單據進行批量審核操作嗎？',
    selectRechargeType: '請選擇充值類型',
    vendorErrorInfo: '供應商不能為空',
    confirmNameMsg: '選中的預付款進行批量審核操作',
    startCompareBill: '發起對賬',
    selectCompareBillStatus: '請選擇對賬狀態',
    relationFlow: '關聯水單',
    flowDetail: '水單明細',
    reconciliationQuery: '付款申請査詢',
    paymentQuery: '發起付款申請',
    settlementUser: '結算人',
    teamDepartureDate: '團出遊日期',
    teamReturnDate: '團歸來日期',
    timeLimitSize: '時間範圍不能超過180天',
    lookTipsInfo: '請查看操作記錄，重新選擇付款單發起付款申請，如有問題聯系技術支援',
    lookTeamSettlementInfo: '有成本結轉單已結算的付款單，請及時進行押金核銷',
    iconContentDetail:
      '根據付款匯總單號進入詳情查看操作記錄，如有問題聯系技術支援，重新發起付款申請',
    selectPayStatus: '請選擇付款狀態',
    payWay: '付款類型',
    selectPayWay: '請選擇付款類型',
    payCreateTime: '付款申請時間',
    payTime: '付款時間',
    againSelectData: '當前勾選的數據不是同一個公司，請重新選擇',
    againSelectStatusData: '當前勾選的數據的團結算狀態不同，請重新選擇',
    savePaymentFail: '保存付款申請失敗',
    payableInfo: '應付信息',
    paymentOrderDetail: '應付單明細',
    paymentReturnDetail: '退款明細',
    paymentWriteOffDetail: '核銷應付單',
    payInfo: '付款信息',
    flowDetailInfo: '水單信息',
    depositInfo: '押金信息',
    unSelectDepositMsg: '您還未選擇押金，請選擇後再提交',
    uploadFile: '上傳附件',
    delayCloseApplySuccess: '付款申請提交成功，可以根據付款匯總單號去共用查詢',
    paymentSubmitPayment: '付款申請保存成功',
    selectBankName: '請先選擇開戶行',
    abandonedInfo: '確定作廢該筆付款申請嗎？',
    flowDetailMsg: '請關聯水單明細',
    returnPayReason: '退款原因',
    selectReason: '請選擇退款原因',
    originPaymentCode: '原付款匯總單號',
    selectApplyDepartment: '請選擇申請部門',
    costAdjustment: '非成本調減導致的供應商退款請選擇非成本調整',
    guideName: '導遊領隊姓名',
    certTypeDesc: '證件類型',
    certNo: '證件號',
    guideCertNo: '導遊證號',
    sapCode: 'SAP財務編碼',
    hrCode: 'HR員工編碼',
    sourceDesc: '導遊領隊身份',
    guideDepartmentName: '社內導遊所屬部門',
    guideCompanyName: '社內導遊所屬公司',
    limitNumTwoDecimal: '請輸入自然數，最多兩位小數',
    smallLimitNum: '請輸入小於或等於應付金額的數字',
    addPaymentOrder: '添加應付單',
    largeNumRules: '請輸入大於或等於未付原幣金額',
    smallNumRules: '請輸入大於或等於未付原幣金額',
    confirmRemoveDetail: '確定移除該條付款單明細嗎？',
    placeholderPayAmountDetail: '請輸入本次付款原幣金額',
    smallUnPayLimitNum: '請輸入小於或等於未付原幣金額',
    inputLeftCostToPay: '請輸入本次付款金額',
    closePrePayment: '清空應付單',
    largeNumReturnAmount: '請輸入大於或等於可退原幣金額',
    smallNumReturnAmount: '請輸入小於或等於可退原幣金額',
    confirmRemoveReturn: '確定移除該條退款明細嗎？',
    confirmDeleteReturn: '您確定清空退款明細中的應付單嗎？',
    inputReturnPaymentAmount: '請輸入本次退款原幣金額',
    smallNumPaymentAmount: '請輸入小於或等於本次付款原幣金額',
    selectReturnNoEmpty: '選擇的數據本次退回金額不能為空',
    selectReturnOrder: '請選擇退款單',
    inputRefundAmount: '請輸入本次退款原幣金額',
    selectPromptInfo: '當【基本信息】選擇”成本調整“時，此區域必須核銷負數的應付單',
    importantPromptInfo:
      '注意：“團代號+訂單號+成本項”對應的待核銷金額+本次退款金額=0，才能核銷，提交付款退款',
    writeOffOrders: '核銷應付',
    confirmRemoveWriteOff: '確定移除該條核銷單明細嗎？',
    inputWriteOffPayAmount: '請輸入本次核銷原幣金額',
    inputContentLimit: '輸入內容不能超過100個漢字',
    validateVerificationList:
      '核銷應付單不能為空，如果不需要核銷應付單，退款原因請選擇【非成本調整】',
    collectionVendorName: '收款方名稱',
    selectPayMode: '選擇押金',
    bankName: '開戶行',
    accountNo: '銀行賬號',
    inputAccountNo: '請輸入銀行賬號',
    updateTime: '處理時間',
    confirmRemark: '確認單備註',
    reviewReason: '審核意見',
    placeholderReviewReason: '請輸入審核意見',
    unReviewReasonEmpty: '審核意見不能全是空格',
    delayCloseRejectInfo: '付款審核已駁回',
    delayClosePassInfo: '付款審核已通過',
    delayLookInfo: '點擊查看，參考樣本',
    delayLookTitle: '參考樣本',
    adult: '成人：',
    children: '兒童：',
    singleRoomDifference: '單房差：',
    interNight: '間夜',
    auditStatusDesc: '付款單狀態',
    auditRemark: '駁回原因',
    placeholderAuditRemark: '請填寫駁回原因',
    unAuditRemarkEmpty: '駁回原因不能全是空格',
    delayCloseReject: '預付款審核已駁回',
    delayClosePass: '付款審核已通過',
    compareAmount: '合計金額',
    compareInfo: '對賬信息',
    billCompareDetailList: '査詢本次對賬明細',
    billCompareDetailListBtn: '査詢',
    downloadSupplierDetail: '下載對賬明細',
    clearloadSupplierDetail: '清空對賬明細',
    addCompareBill: '添加對賬明細',
    compareBill: '對賬完成',
    placeholderCompareAmount: '請輸入本次對賬原幣金額',
    placeholderRemark: '請輸入備註信息',
    confirmRemoveCompareBill: '確認移除此條發票數據不參與對賬嗎',
    confirmClearCompareBill: '確定清空對賬明細嗎',
    compareBillSuccess: '對賬成功',
    compareBillDetailNoEmpty: '對賬明細不可為空',
    confirmAbandonedBill: '確定作廢該筆賬單嗎',
    downloadTemplate: '下載範本',
    templateInfo: '請按範本導入快速匹配',
    uploadFileInfo: '點擊或將文件拖拽到這裡上傳',
    fileExtension: '支持拓展名：xls、xlsx',
    downloadResultsName: '下載結果',
    downloadResults: '下載本次對賬結果',
    thisTimeImport: '本次導入：',
    importSuccess: '導入成功：',
    importFail: '導入失敗：',
    importUnit: '筆，共計',
    importFileSize: '上傳文件大小不能超過5MB',
    fileExtensionError: '類型只能是xls、xlsx',
    fileSizeLimit: '同時只能上傳一個文件',
    largeNumInput: '請輸入大於或等於未對賬金額',
    smallNumInput: '請輸入小於或等於未對賬金額',
    addCompareBillTitle: '添加對賬明細',
    selectCompareBill: '請選擇對賬明細',
    companyName: '採購公司',
    vendorName: '供應商名稱',
    rechargeType: '充值類型',
    caiwuCheckStatus: '財務審核狀態',
    teamNo: '團代號',
    createUser: '申請人',
    companyNameInfo: '公司名稱',
    queryConditions: '查詢條件',
    depositBalance: '剩餘原幣金額',
    depositApplication: '押金申請',
    depositInfoTips: '有被退回的押金申請，請進入充值明細查看原因並重新發起',
    totalRecharge: '充值原幣金額',
    totalTransaction: '交易原幣金額',
    refundAmount: '退回原幣金額',
    purchaseInfo: '請先選擇採購公司',
    buChuName: '部處名稱',
    noLimit: '所有部門',
    applyMoney: '申請金額',
    plsEnterapplyMoney: '請輸入申請金額',
    chooseGroupCode: '選擇團代號',
    chooseSuplierTips: '請先選擇供應商名稱，再選擇開戶行',
    applyRemark: '申請說明',
    plsEnterApplyRemark: '請輸入申請說明',
    attachments: '附件',
    groupCodeDetail: '團代號詳情',
    groupName: '團名稱',
    plsEnterGroupName: '請輸入團名稱',
    productId: '產品碼',
    plsEnterProductId: '請輸入產品碼',
    groupType: '團類型',
    groupRanage: '團期',
    groupDepartment: '組團部門',
    groupMan: '組團人',
    plsChooseSuplierName: '請選擇供應商名稱',
    plsEnterApplyAmount: '請填寫申請金額',
    enterTips: '請填寫大於0的數值，最多兩位小數',
    applyTrimTipsMsg: '申請說明不能全是空格',
    hasGroupYajin: '有團押金',
    attachmentsEmptyWarning: '附件不能為空',
    tradeDetail: '交易明細',
    rechangeDetail: '充值明細',
    reBackDetail: '退回明細',
    limitText: '僅申請部門',
    plsEnterRemark: '請填寫備註',
    remarkTrimTipsMsg: '備註不能全是空格',
    depositTransferApply: '押金轉出申請',
    transfeMoney: '轉出金額',
    plsEnterTransfeMoney: '請輸入轉出金額',
    transferOutDept: '轉出部門',
    transferToDepartment: '轉入部門',
    plsEnterTransferToDepartment: '請輸入轉入部門',
    uploadFileText: '上傳文件',
    suportType: '支持格式doc、docx、xls、xlsx、txt、pdf、jpg、png、bmp、csv',
    plsChooseSuliper: '請選擇供應商',
    plsEnterLessNumTips: '請填寫小於等於餘額{0}的數值',
    reBackOrderNum: '退回單號',
    plsEnterReBackOrderNum: '請輸入退回單號',
    reBackStatus: '退回狀態',
    currencyKind: '幣種',
    prepaidRechargeOrderNum: '預付充值單號',
    listOrderNum: '水單編號',
    reBackRemark: '退回說明',
    reBackApplyer: '退回申請人',
    transferInfo: '是否結轉到共享系統？ 確認之後，重新提交共享緩衝區。',
    voidInfoTips: '是否作廢此單據？ 作廢後可以重新提交申請。',
    linkInchangeOrderNum: '關聯充值單號',
    enterRefundMoney: '請輸入退回金額',
    receiptCompany: '收款公司',
    receiptType: '收款方式',
    chooseInCoinMethod: '請選擇收款方式',
    refundTextInfo: '押金退回說明',
    plsEnterMoney: '請填寫提取金額',
    lessNumInfoText: '請填寫小於等於{0}的數值',
    plsEnterRefundTextInfo: '請填寫押金退回說明',
    checkNumEqualMsg: '押金退回金額和水單認領金額總和不相等，請檢查確認',
    refundSuccessMsg: '押金退回申請成功',
    wordFileTypeLimit: '類型只能是doc、docx、xls、xlsx、txt、pdf、jpg、png、bmp、csv',
    inchangeApply: '押金充值申請',
    noGroupDeposit: '無團押金',
    plsEnterInchangeNum: '請填寫充值金額',
    plsEnterInchangeRemark: '請填寫充值說明',
    infoTrimTips: '充值說明不能全是空格',
    plsEnterApplyRemarkText: '請填寫申請說明',
    applySuccessInfo: '申請成功',
    balabalaTips: '客商主數據中交易方性質不是客商，請先去主數據系統完成申請',
    noGroupCodeWarning: '有團押金時，團代號不能為空',
    waterBillDetails: '水單明細',
    beneficiaryBankAccountNum: '收款銀行賬號',
    claimAmount: '認領金額',
    senderInformation: '匯款人信息',
    currencyOfArrival: '到賬幣種',
    payOrderNum: '付款單號',
    plsEnterPayOrderNum: '請輸入付款單號',
    inchangeOrderNum: '充值單號',
    plsEnterInchangeOrderNum: '請輸入充值單號',
    applyTime: '申請時間',
    verificationAmount: '核銷金額',
    balance: '餘額',
    returnBack: '退回',
    turnOut: '轉出',
    sizeLimited: '上傳圖片大小不能超過5MB',
    repeatedInfo: '文件名稱重複，請修改後重新上傳',
    useLimit: '使用範圍',
    customerReconciliation: '客戶對賬',
    collectionProgress: '收款進度查詢',
    reconciliationInterval: '對賬區間',
    reconciliationStart: '對賬開始時間',
    reconciliationEnd: '對賬結束時間',
    batchPrint: '批量列印',
    batchPrintConfrim: '確認批量列印',
    batchPrintTip: '您選中的對賬單的剩餘待收原幣金額{0}將重新生成電子對賬單，進行批量列印',
  },
}
