export default {
  '065318-0': '第',
  '065318-1': '天',
  '065318-2': '確認刪除該資源類型',
  '065318-3': '資源類型',
  '065318-4': '已添加過相同資源類型',
  '065318-5': '在行程第',
  '065318-6': '天使用的',
  '065318-7': '最多50個',
  '065318-8': '天使用的跟團資源最多1個',
  '065318-9': '每天只能有一個套餐',
  '065318-10': '已添加第',
  '065318-11': '天使用的該資源',
  '065318-12': '天使用的資源行程天數必須相同',
  '065318-13': '資源結束的行程天數不能晚於產品行程天數',
  '065318-14': '酒店房型價格或酒店只能添加一種，請重新選擇。',
  '065318-15': '入住晚數不能超過酒店在產品行程中可用的晚數',
  '065318-16': '請打包',
  '065318-17': '資源',
  '259617-0': '選擇資源',
  '259617-1': '房型價格',
  '259617-2': '酒店',
  '259617-3': '酒店名稱',
  '259617-4': '請輸入',
  '259617-5': '房型價格名稱',
  '259617-6': '査詢',
  '259617-7': '房型價格編號',
  '259617-8': '酒店編號',
  '259617-9': '城市',
  '259617-10': '資源負責人',
  '259617-11': '晚數',
  '259617-12': '操作',
  '259617-13': '選擇酒店',
  '327262-0': '保存',
  '327262-1': '添加資源後請先保存，再進行後續操作。',
  '327262-2': '銷售價：',
  '327262-3': '產品直接銷售給客人的報價，如客人在PC、訂單下單時看到的價格。',
  '327262-4': '同行價：',
  '327262-5':
    '產品分銷給同行的價格，僅同業合約客戶使用同行價。支持設置多個同行價，包括：同行價、指定客商等級同行價、指定客商同行價，規則優先級為：指定客商同行價>指定客商等級同行價>同行價。',
  '327262-6': '公司內部結算價：',
  '327262-7':
    '本公司部門內部間的內部結算價格，同時也是本產品共享到事業群所有企業進行分銷時的內部結算價格（需要先選中本產品[基本信息-共用到事業群]標記）。',
  '327262-8': '暫無資源',
  addResourceType: '添加資源類型',
  '566870-0': '收起',
  '566870-1': '展開',
  '566870-2': '資源默認按',
  '566870-3': '請選擇',
  '566870-4': '加價，',
  '566870-5': '銷售價',
  '566870-6': '= 成本價',
  '566870-7': '同行價',
  '566870-8': '公司內部結算價',
  '566870-9': '本條產品-同類資源的默認加價，可在[資源團期價格]頁單獨調整。',
  '566870-10': '在產品行程的第',
  '566870-11': '第',
  '566870-12': '天',
  '566870-13': '天使用',
  '566870-14': '添加資源',
  '566870-15': '行程',
  '566870-16': '資源選擇方式',
  '566870-17': '資源編號',
  '566870-18': '資源名稱',
  '566870-19': '酒店名稱',
  '566870-20': '晚數',
  '566870-21': '晚',
  '566870-22': '行程天數',
  '566870-23': '單位',
  '566870-24': '資源負責人',
  '566870-25': '操作',
  '566870-26': '添加',
  '566870-27': '删除',
  '566870-28': '請輸入',
  '566870-29': '請輸入≥0數字',
  '566870-30': '請輸入最多兩位的小數',
  '566870-31': '不能大於銷售價',
  '566870-32': '請選擇類型',
  '566870-33': '元',
  '566870-34': '確認刪除該資源',
  '566870-35': '刪除資源',
  '880202-0':
    '1、資源在產品的整個行程中第幾天開始使用到第幾天結束。 結束日期=行程開始天數+資源行程天數-1。 如跟團資源3天2晚，在產品的第1天使用，則資源行程為第1~3天',
  '880202-1':
    '2、行程開始天數用於計算資源在產品的售價。 如跟團資源第2天開始使用，產品在5.1號團期，使用的跟團資源在5.2號的成本價',
  '880202-2': '客人購買產品內包含的資源時，可選擇/購買的方式。',
  '880202-3': '必選：必選的資源，客人需全部購買； 產品銷售價格也包含所有資源。',
  '880202-4':
    '一項起選：資源類型下，同一天使用的一項起選資源，客人可購買其中一項或多項； 產品銷售價格默認包含最低價的資源。',
  '880202-5':
    '任選一項：資源類型下，同一天使用的任選一項資源，客人只能購買其中一項； 產品銷售價格默認包含最低價的資源。',
  '880202-6': '可選：可選的資源，客人可以選擇加購或不加購； 產品銷售價格不包含可選的資源。',
  '207590-0': '銷售價，即產品銷售給散客的價格，如客人在中旅旅行小程式看到的價格。',
  '207590-1':
    '同行價，即產品分銷給同行或大客戶的價格，如訂單接單頁銷售類型選擇“分銷”時看到的價格。',
  '207590-2': '公司內部結算價，即提供產品的公司與分銷的所屬企業之間內部結算的價格。',
  '416411-0': '產品團期',
  '416411-1': '資源類型',
  '416411-2': '請選擇資源類型',
  '416411-3': '本次加價應用至該資源類型的所有資源上。',
  '416411-4': '選擇資源',
  '416411-5': '全部',
  '416411-6': '本次加價應用至該資源類型的全部資源和升級方案。',
  '416411-7': '可銷售日期',
  '416411-8': '加價類型',
  '416411-9': '請選擇',
  '416411-10': '加價幅度',
  '416411-11': '銷售價：',
  '416411-12': '產品直接銷售給客人的報價，如客人在PC、訂單下單時看到的價格。',
  '416411-13': '同行價：',
  '416411-14':
    '產品分銷給同行的價格，僅同業合約客戶使用同行價。支持設置多個同行價，包括：同行價、指定客商等級同行價、指定客商同行價，規則優先級為：指定客商同行價>指定客商等級同行價>同行價。',
  '416411-15': '公司內部結算價：',
  '416411-16':
    '本公司部門內部間的內部結算價格，同時也是本產品共享到事業群所有企業進行分銷時的內部結算價格（需要先選中本產品[基本信息-共用到事業群]標記）。',
  '416411-17': '產品價格為打包的各類資源價格的總和。',
  '416411-18': '批量編輯價格',
  '416411-19': '修改資源報價',
  '416411-20': '請選擇可銷售日期',
  '416411-21': '加價類型不能為空',
  '416411-22': '資源類型不能為空',
  '416411-23': '資源不能為空',
  '416411-24': '修改成功',
  editPrice: '編輯價格',
  storeBan: '庫存看板',
  '010362-0': '產品自定義標籤',
  '010362-1': '標籤對客呈現，請選擇與產品行程匹配的標籤； 呈現順序為所選擇的先後順序。',
  '010362-2': '已選：',
  customTag: '自定義標籤',
  editTag: '編輯標籤',
  '164840-0': '配置出發城市',
  '164840-1': '請在此配置產品的交通出發城市，默認行程結束後返回出發城市',
  '164840-2': '未選出發城市',
  '164840-3': '已選出發城市',
  '164840-4': '請輸入關鍵字查找',
  '311734-0': '操作前建議查看',
  '311734-1': '交通規則參考示例',
  '311734-2': '以全國出發，北京5天4晚雙飛遊為例，參考配置如下',
  '311734-3': '第',
  '311734-4': '程',
  '311734-5': '全國',
  '311734-6': '出發地',
  '311734-7': '最早出發時間',
  '311734-8': '後出發',
  '311734-9': '目的地',
  '311734-10': '最晚抵達時間',
  '311734-11': '前抵達',
  '311734-12': '規則解釋：第',
  '311734-13': '天，',
  '311734-14': '飛往',
  '311734-15': '，當天',
  '311734-16': '後出發，',
  '311734-17': '前抵達的飛機',
  '311734-18': '注意：',
  '311734-19':
    '1、需配置全國出發的城市，請在“配置出發城市”中維護，如上海、廣州、南京、深圳等出發城市',
  '311734-20': '2、默認第1程（去程）的出發城市和末程（返程）的抵達城市一致',
  '311734-21': '參考示例',
  '311734-22': '，系統將根據配置的規則查找符合的機票。',
  '311734-23': '北京',
  '545884-0': '取消',
  '545884-1': '保存',
  '545884-2': '交通配置',
  '545884-3': '往返',
  '545884-4': '多程（含缺口程）',
  '545884-5': '第',
  '545884-6': '程',
  '545884-7': '出發地',
  '545884-8': '最早出發時間',
  '545884-9': '後出發',
  '545884-10': '目的地',
  '545884-11': '最晚抵達時間',
  '545884-12': '前抵達',
  '545884-13': '配置出發城市（全國）',
  '545884-14': '新增一程',
  '545884-15': '機票範圍',
  '545884-16': '全國',
  '670820-0': '請選擇交通配置',
  '670820-1': '機票範圍不能為空',
  '670820-2': '天數不能為空',
  '670820-3': '不可超過行程天數',
  '670820-4': '請輸入大於0的整數',
  '670820-5': '未配置出發地城市',
  '670820-6': '未配置最早出發時間',
  '670820-7': '未配置目的地城市',
  '670820-8': '未配置最晚抵達時間',
  '670820-9': '未配置出發城市',
  '757479-0': '機票多程適用於以下場景：',
  '757479-1': '前往多個目的地',
  '757479-2': '出發地和返回地不同',
  '757479-3': '去程',
  '757479-4': '上海',
  '757479-5': '北京',
  '757479-6': '返程',
  '757479-7': '天津',
  '757479-8': '杭州',
}
