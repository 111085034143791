export default {
  companyName: '公司名稱',
  currencyCode: '幣種',
  contacterName: '客戶連絡人',
  updateUserName: '申請人',
  config: {},
  settlement: {
    totalAmount: '總收入（含稅）本幣',
    receiptsConfirmTotalAmount: '總實收款原幣',
    totalCost: '總成本（含稅）本幣',
    totalGrossProfit: '總毛利（含稅）本幣',
    totalGrossProfitMargin: '總毛利率（含稅）本幣',
    totalAmountNoTax: '總收入（不含稅）本幣',
    totalCostNoTax: '總成本（不含稅）本幣',
    totalGrossProfitNoTax: '總毛利（不含稅）本幣',
    totalGrossProfitMarginNoTax: '總毛利率（不含稅）本幣',
    unsettledIncome: '未結收入（含稅）本幣',
    unsettledCost: '未結成本（含稅）本幣',
    touristNumber: '團人數',
    teamDays: '團天數',
    teamManDays: '人天數',
    teamArchiveStatus: '團檔案狀態',
    settlementNumber: '結算次數',
    settlementStatus: '結算狀態',
    orderTime: '下單時間',
    purchasePeople: '採購人員',
    cost: '應付（含稅）原幣',
    standardCurrencyCost: '應付（含稅）本幣',
    standardCurrencyTax: '稅額本幣',
    actualPayAmount: '實付原幣金額',
    income: '應收（含稅）原幣',
    standardCurrencyIncome: '應收（含稅）本幣',
    standardCurrencyCommissionAmount: '傭金本幣金額',
    receiptsConfirmAmount: '實收原幣金額',
    companyInnerSettlePrice: '公司內部結算價',
    orderTotalPrice: '訂單總價',
    resourceTotalPrice: '資源總價',
    additionalTotalPrice: '其他附加項',
    additionalType: '附加項類型',
    localCurrencyIncome: '收入（含稅）本幣',
    localCurrencyCost: '成本（含稅）本幣',
    carryOverTime: '結轉時間',
    settlementSeq: '結算批次',
    batchNo: '毛利審批單號',
    batchStatus: '審批狀態',
    currentAuditUserName: '當前審批人',
    currentTotalIncome: '本次收入（含稅）本幣',
    currentTotalCost: '本次成本（含稅）本幣',
    taskName: '審批節點',
    assignUserName: '審批人',
    taskResult: '審批動作',
    auditTime: '審批時間',
    settlementDepart: '結算部門',
    createTeamDate: '生成日期',
    teamTouristNumber: '團出遊人',
    currentTotalProfit: '本次毛利（含稅）本幣',
    currentIncomeNoTax: '本次收入（不含稅）本幣',
    currentCostNoTax: '本次成本（不含稅）本幣',
    currentProfitNoTax: '本次毛利（不含稅）本幣',
  },
  receivePay: {
    receiptRefundCode: '收款單號',
    sourceDocumentNo: '來源單據號',
    collectCode: '匯總單號',
    receiptCollectCode: '收款匯總單號',
    collectionOperatorNew: '收款操作人',
    fsscStatus: '收款單狀態',
    receiptAmount: '收款金額',
    salesmanName: '收款業務員',
    receiptRefundType: '收款類型',
    receiptChannelType: '收款方式',
    fsscTransferCode: '共享結轉單號',
    receiptRefundTime: '收款時間',
    adjustingAmount: '待調賬金額',
    adjustedAmount: '調賬金額',
    outTeamNo: '轉出團代號',
    inTeamNo: '轉入團代號',
    adjustedTime: '調賬時間',
    sourceReceiptCode: '轉出單號',
    transferredOutAmount: '轉出金額',
    outTeamTypeName: '轉出團類型',
    inTeamTypeName: '轉入團類型',
    orderApplyingAmount: '訂單應收金額',
    refundCode: '退款單號',
    refundFsscStatus: '退款單狀態',
    refundSalesman: '退款業務員',
    touristsNumber: '出遊人數',
    refundStatus: '退款狀態',
    refundType: '退款類型',
    refundTime: '退款時間',
    refundApplicant: '退款申請人',
    refundApplyTime: '退款申請時間',
    departureDate: '出遊日期',
    sharedBillNo: '共享報賬單號',
    associatedPayableTeamNo: '關聯的應付團代號',
    serialNumber: '收款流水號',
    handlingFee: '手續費',
    handlingFeeRate: '手續費率',
    actualRefundAmount: '實際退款金額（不含手續費）',
    preReceiptCode: '預收款單號',
    claimType: '認領狀態',
    amount: '金額',
    transferInAmount: '轉入金額',
    customerId: '客戶編碼',
    leftAmount: '剩餘金額',
    refundChannelType: '退款方式',
    backAmountNum: '退款金額',
    operateType: '使用類型',
    preReceiptAmount: '預收款金額',
    writeOffOrderId: '核銷訂單號',
    useDate: '使用時間',
    useAmount: '核銷金額',
    writeOffStatus: '核銷狀態',
    transferAmount: '轉賬金額',
    usedAmount: '已用金額',
  },
  invoice: {},
  deposit: {
    prepayRechargeCode: '預付充值單號',
    applyAmount: '充值原幣金額',
    standardApplyAmount: '充值本幣金額',
    balance: '剩餘原幣金額',
    prepaidSource: '充值記錄來源',
    fsscBackFlag: '押金使用情况',
    usedAmount: '本次使用原幣金額',
    departmentLimitDec: '是否限制部門',
  },
  payment: {
    subPaymentCode: '應付單號',
    standardCost: '成本（含稅）本幣金額',
    paymentStandardCost: '應付本幣金額',
    cost: '成本（含稅）原幣金額',
    paymentCost: '應付原幣金額',
    updateUserName: '審核人',
    unComparedCost: '剩餘對賬原幣金額',
    unreconciledCost: '未對賬原幣金額',
    paymentCode: '付款匯總單號',
    leftCost: '未付原幣金額',
    teamSettlementStatus: '團結算狀態',
    billCompareCode: '對賬單號',
    compareAmount: '對賬金額合計',
    compareAmountSums: '合計金額',
    paymentVoucherType: '應付單據類型',
    originalAmount: '本次退款（含稅）原幣金額',
    paymentOriginalAmount: '本次付款（含稅）原幣金額',
    originalAmount2: '本次退款原幣金額',
    paymentOriginalAmount2: '本次付款原幣金額',
    standardLeftCost: '未付本幣金額',
    vendorId: '供應商單號',
    payWay: '已付原幣金額',
    auditStatusDesc: '付款單狀態',
    businessVoucherType: '傭金類型',
    shopkeeperName: '店主姓名',
    commissionCost: '傭金（含稅）原幣金額',
    dockingChannelDesc: '確認反饋類型',
    resourceId: '資源編號',
    adultCost: '單價',
    adultTotal: '總價',
    applyTimeDate: '申請日期',
    statusDesc: '對賬狀態',
    operatorName: '對賬操作人',
    operatorName2: '操作人',
    compareAmountDetail: '本次對賬原幣金額',
    originPaymentCode: '原付款匯總單號',
    payWayDesc: '付款類型',
    payCreateTime: '付款申請時間',
    payTime: '實際付款日期',
    invoiceCode: '發票號',
    teamDepartureDateStart: '團出遊日期',
    teamDepartureDateEnd: '團歸來日期',
    paymentReturnCode: '付款退款單號',
    payAmount: '本次退款(含税)本幣金額 | 本次付款(含税)本幣金額',
    payAmountWithoutTax: '本次退款（不含稅）本幣金額|本次付款（不含稅）本幣金額',
    standardPayAmount: '本次退款本幣金額 | 本次付款本幣金額',
    returnLeftCost: '可退原幣金額',
    refundAmount: '本次退款原幣金額',
    pendingWriteOffLeftCost: '待核銷原幣金額',
    writeOffPayAmount: '本次核銷原幣金額',
    fsscStatusDescNew: '收款狀態',
    compareOriginalCurrencyAmount: '對賬原幣金額',
    collectionOriginalCurrencyAmount: '收款原幣金額',
    pleaseConfirmTips: '此操作成功後，將釋放對賬單中對應發票的已收金額，可返回對賬列表重新進行收款',
    pleaseConfirmRevokedTips:
      '此撤銷將收款單狀態變爲”待提交“，可重新在【收款進度查詢列表】針對此收款單發起收款',
    reconciledCost: '對賬原幣金額',
    reconciledTime: '對賬時間',
    receivedReconciledCost: '已收原幣金額',
    residueReconciledCost: '剩余待收原幣金額',
  },
  sourcingCompanyName: '採購公司',
  originalCurrency: '原幣幣種',
  teamNo: '團代號',
  teamType: '團類型',
  teamName: '團名稱',
  teamDepartureDate: '團期',
  orderId: '訂單號',
  exchangeRate: '匯率',
  taxRate: '稅率',
  taxAmount: '稅額',
  bufferCostItemName: '成本項',
  businessId: '業務單據號',
  businessType: '業務單據類型',
  status: '審核狀態',
  rechargeType: '充值類型',
  teamCreateUserName: '建團人',
  resourceTypeDesc: '資源類型',
  applyTime: '申請時間',
  createTime: '創建時間',
  updateTime: '處理時間',
  generateTime: '生成時間',
  settlementUserName: '結算人',
  vendorName: '供應商名稱',
  vendor: '供應商',
  payModeName: '付款方式',
  payStatusDesc: '付款狀態',
  invoiceTotalAmount: '發票金額',
  companySettlementCode: '成本結轉單號',
  companySettlementCodes: '結轉單號',
  companySettlement: '結轉公司',
  departureDate: '使用日期',
  resourceDepartureDate: '資源使用日期',
  resourceName: '資源名稱',
  resourceDetail: '資源詳情',
  amount: '原幣金額',
  standardAmount: '本幣金額',
  applyRemark: '申請說明',
  teamInvoiceTypeDesc: '團發票類型',
  invoiceType: '發票類型',
  applyPersonDepartmentName: '申請人部門',
  departmentName: '申請部門',
  remark: '備註',
  productCompany: '產品公司',
  productId: '產品碼',
  settlementStatus: '結算單狀態',
  settlementCode: '結算單號',
  customerName: '客戶名稱',
  settlementItem: '結轉項',
  productName: '產品名稱',
  operatorName: '創建人',
  customerType: '客戶類型',
  realCustomerName: '實際客戶',
  realName: '實際名稱',
  createDate: '創建日期',
  terminalNo: '終端號',
  saleCompanyName: '銷售公司',
  groupDepartment: '組團部門',
  orderStatus: '訂單狀態',
  flowList: {
    flowId: '水單編號',
    receiptAccount: '收款銀行賬號',
    actDate: '水單日期',
    claimAmount: '認領原幣金額',
    payName: '匯款人信息',
    receiptCurrency: '到賬幣種',
    bankName: '收款開戶行',
    claimTime: '認領時間',
    isPartialClaim: '是否可部分認領',
    amountOfMoney: '金額',
    useClaimAmount: '可認領金額',
    tableClaimAmount: '認領金額',
  },
  relatedParty: {
    notSettlementIncome: '關聯交易未結算收入',
    notSettlementCost: '關聯交易未結算成本',
    relatedSettlementCode: '關聯交易結算單號',
    settlementAuditStatus: '結轉單狀態',
    settlementCost: '採購關聯交易金額',
    settlementIncome: '銷售關聯交易金額',
    transactionType: '關聯交易類型',
    transactionTypeDesc: '結轉類型',
    transactionTypeCost: '結轉金額',
    relatedCompanySettlementCode: '關聯結轉單號',
    standardCurrencyAmount: '結轉金額（含稅）',
    amountNoTax: '結轉金額（不含稅）',
    businessDocId: '確認單號',
    salesDepartment: '銷售部門',
    sourcingDepartment: '採購部門',
    userName: '業務員',
    settlementPrice: '結算價',
    startToEndDate: '賬期',
    paymentAmount: '付款金額',
    settlementOperationUser: '結轉單操作人',
    receiptSettlementCode: '收入結轉單號',
    applyCollectionDepartment: '申請收款部門',
    receivableAmount: '應收金額',
    receiptsAmount: '實收金額',
    applyingAmount: '申請中金額',
    invoicedAmount: '已開票金額',
    invoiceAmount: '未開票金額',
    validDate: '月份',
    productCompanyProfit: '產品公司利潤',
    productCompanyProfitRatio: '產品公司利潤占比',
    purchaseCompanyProfit: '採購公司利潤',
    purchaseCompanyProfitRatio: '採購公司利潤占比',
    salesCompanyProfit: '銷售公司利潤',
    salesCompanyProfitRatio: '銷售公司利潤占比',
    totalProfitProportion: '利潤占比合計',
    useStatusDesc: '狀態',
    collectionBank: '收款銀行',
    collectionBankCode: '銀行賬號',
    handlingFeeRate: '手續費率',
    handlingFee: '手續費',
    flowClaimCode: '支付水單編號',
    invesFlagEnumList: '水單待查標記',
    receiptCompanyName: '收款公司',
    entrustDepartmentName: '結轉單操作部門',
    collectionCodeCreateTime: '收款單生成時間',
    receiptAmount: '本次收款金額',
    actualRefundAmount: '到賬金額',
    financeStatusDesc: '共享審核狀態',
    carryOverUserName: '結轉操作人',
    teamPeopleCount: '團出遊人數',
    totalIncomeTax: '總收入（含稅）',
    incomeTax: '收入（含稅）',
    totalIncomeNotTax: '總收入（不含稅）',
    costTax: '成本（含稅）',
    totalCostTax: '總成本（含稅）',
    totalCostNotTax: '總成本（不含稅）',
    totalGrossProfitTax: '總毛利（含稅）',
    totalGrossProfitNotTax: '總毛利（不含稅）',
    grossProfitMarginTax: '總毛利率（含稅）',
    grossProfitMarginNotTax: '總毛利率（不含稅）',
    departureDateBasic: '出發日期',
    totalOrderIncomeTax: '訂單總收入（含稅）',
    totalOrderIncomeNotTax: '訂單總收入（不含稅）',
    totalOrderCostTax: '訂單總成本（含稅）',
    totalOrderCostNotTax: '訂單總成本（不含稅）',
    totalOrderGrossProfitTax: '訂單總毛利（含稅）',
    totalOrderGrossProfitNotTax: '訂單總毛利（不含稅）',
    grossOrderProfitMarginTax: '訂單總毛利率（含稅）',
    grossOrderProfitMarginNotTax: '訂單總毛利率（不含稅）',
    productTerminal: '產品端',
    purchaseTerminal: '採購端',
    saleTerminal: '銷售端',
    purchaseCompanySettlementCode: '成本結算單號',
    profitLabel: '利潤分配',
    totalProfit: '總利潤',
    purchaseCompanyIncome: '產品與採購結算價',
    saleCompanyCost: '產品與銷售結算價',
    totalTax: '總稅額',
    resources: '資源',
    quantity: '數量',
    incomeAmountTax: '收入金額（含稅）',
    incomeAmountNotTax: '收入金額（不含稅）',
  },
}
