export default {
  '463211-0': '資源類型',
  '463211-1': '請選擇',
  '463211-2': '資源名稱',
  '463211-3': '系列（非必填）',
  '463211-4': '必填，填寫目的地+幾日幾晚',
  '463211-5': '非必填，請輸入副標題',
  '463211-6': '單位',
  '463211-7': '景點',
  '463211-8': '可用人數',
  '463211-9': '資源負責人',
  '463211-10': '負責人部門',
  '463211-11': '自營',
  '463211-12': '自組團業務專用，勾選後只能選擇自組專屬供應商，請謹慎勾選',
  '463211-13': '供應商',
  '463211-14': '請選擇供應商',
  '463211-15': '行程天數',
  '463211-16': '請輸入天數',
  '463211-17': '天',
  '463211-18': '請輸入晚數',
  '463211-19': '晚',
  '463211-20': '集合城市',
  '463211-21': '請輸入集合城市',
  '463211-22': '集合時間',
  '463211-23': '全天',
  '463211-24': '時間',
  '463211-25': '請選擇時間',
  '463211-26': '散團城市',
  '463211-27': '請輸入散團城市',
  '463211-28': '散團時間',
  '463211-29': '請輸入',
  '463211-30': '末站目的地城市',
  '463211-31': '請輸入末站目的地城市',
  '463211-32': '是否含場次票',
  '463211-33': '是',
  '463211-34': '否',
  '463211-35': '是否包含往返交通',
  '463211-36': '不包含往返交通',
  '463211-37': '包含往返交通',
  '463211-38': '往返交通',
  '463211-39': '往-',
  '463211-40': '-返',
  '463211-41': '交通信息說明',
  '463211-42': '參考航班（車次）等信息',
  '463211-43': '是否含門票',
  '463211-44': '門票數量',
  '463211-45': '成人數',
  '463211-46': '成人',
  '463211-47': '兒童數',
  '463211-48': '兒童',
  '463211-49': '每單最多預訂',
  '463211-50': '每單最多可預訂數量',
  '463211-51': '資源銷售須知',
  '463211-52': '僅內部人員可見，不在外網或出遊通知展示',
  '463211-53': '取消',
  '463211-54': '保存',
  '463211-55': '保存並新增',
  '014017-0': '供應商產品信息',
  '014017-1': '產品編碼',
  '014017-2': '產品描述',
  '014017-3': '產品名稱',
  '014017-4': '產品是否可退',
  '014017-5': '產品停止售賣日期',
  '014017-6': '產品允許售賣日期',
  '014017-7': '允許購票顧客的最大年齡',
  '014017-8': '允許購票顧客的最小年齡',
  '014017-9': '每單最大購買數量',
  '014017-10': '成人',
  '014017-11': '兒童',
  '014017-12': '老人',
  '014017-13': '不確認',
  '256825-0': '1.命名形式：【系列】+主標題+ &lt； 副標題&gt；',
  '256825-1': '2.系列：非必填',
  '256825-2': '3.主標題：包括目的地+幾日幾晚+行程類型',
  '256825-3':
    '目的地：行程中為3個及3個以上國家或地區的，目的地用縮寫，行程中為同一國家的2個及2個以上城市名稱的，中間用“-”相連，例如：法意瑞、泰新馬； 首爾-濟州、昆明-大理-麗江；',
  '256825-4': '行程天晚：使用阿拉伯數字，例如：“6日4晚”，天數統一使用“幾日”；',
  '256825-5': '行程類型：一般分為跟團遊、自由行等。',
  '256825-6':
    '4.副標題：行程內的景點、贈送項、住宿或者是進出城市等特色，例如：縱覽全景，送掛件，住宿升級，上海出杭州回等。',
  '256825-7': '5.杜絕“純玩、品質、豪華”等字眼，務必精簡意賅。',
  '256825-8':
    '6.名稱長度總計不超過70個字符數，例如：【系列名稱】北京5日4晚跟團遊&lt； 一車雙導+圓夢升旗儀式+瞻觀毛主席紀念堂+探秘恭王府+品全聚德烤鴨+24小時無憂接送',
  '256825-9': '安心出遊&gt；',
  '256825-10': '7.對於已通過的資源，請勿輕易變更資源名稱。',
  '256825-11': '計價方式分為按人計價和按套計價',
  '256825-12': '1、按人計價：按人管理成本價和庫存，對客呈現元/人，支持按成人、兒童預訂。',
  '256825-13': '2、按套計價：按份管理成本價和庫存，對客呈現元/份，預訂時不區分成人/兒童。',
  '256825-14': '單位將對客呈現，目前有：',
  '256825-15': '1、人：按人管理成本價和庫存，對客呈現元/人，可按成人、兒童預訂。',
  '256825-16': '2、套：按套管理成本價和庫存，對客呈現元，預訂時不區分成人、兒童，購買套數。',
  '256825-17':
    '3、張：按人群（成人、兒童、老人、學生）管理成本價和庫存，對客呈現元，僅需維護成人價。',
  '256825-18': '單資源中使用的人數，將用來計算資源對客的人均價格',
  '256825-19':
    '單位選擇後不可更改，請知曉； 當與其他資源一起打包時，適用人數默認2人入住，計算人均對客價格。',
  '256825-20':
    '單位選擇後不可更改，請知曉； 當與其他資源一起打包時，默認按門票成人數計算人均對客價格。',
  '256825-21': '單位選擇後不可更改，請知曉',
  '256825-22': '單位不可更改',
  '256825-23': 'NBS專屬供應商，僅在NBS內部使用，不進入財務系統。',
  '380736-0': '全天',
  '380736-1': '請選擇資源類型',
  '380736-2': '請選擇計價方式',
  '380736-3': '資源名稱總長度不能超過70',
  '380736-4': '資源主標題必填，不能為空',
  '380736-5': '資源副標題必填，不能為空',
  '380736-6': '資源負責人必填，不能為空',
  '380736-7': '負責人部門必填，不能為空',
  '380736-8': '資源供應商必填，不能為空',
  '380736-9': '請輸入大於0的數字',
  '380736-10': '請輸入數字',
  '380736-11': '請輸入≥1，≤999的數字',
  '380736-12': '請輸入≥0，≤999的數字',
  '380736-13': '城市必填，不能為空',
  '380736-14': '是否包含往返交通必選，不能為空',
  '380736-15': '集合城市必填，不能為空',
  '380736-16': '散團城市必填，不能為空',
  '380736-17': '集合時間必填，不能為空',
  '380736-18': '散團時間必填，不能為空',
  '380736-19': '往返交通必選，不能為空',
  '380736-20': '請選擇是否打包門票',
  '380736-21': '請輸入大於0小於100的數字',
  '380736-22': '請輸入大於等於0小於100的數字',
  '380736-23': '大於0，小於100的數字',
  '380736-24': '請選擇計價單位',
  '380736-25': '請添加景點',
  '380736-26': '請選擇是否包含場次票',
  '543394-1': '保存成功',
  '543394-2': '該景區已添加過',
  '727507-0': '不占床',
  '727507-1': '占床',
  '727507-2': '（已含機場建設費、燃油稅）',
  '727507-3': '（已含機場建設費）',
  '727507-4': '（已含燃油稅）',
  '727507-5': '無',
  '727507-6': '司導和領隊小費',
  '727507-7': '司導小費',
  '727507-8': '合約一經簽訂且付全款，團隊機票、列車票、船票即為出票，不得更改、簽轉、退票。',
  '727507-9': '飛行時間、車程時間、船程時間以當日實際所用時間為准。',
  '727507-10': '本產品如因淡季或收客人數較少，有可能與相近方向的發班線路拼車出遊，屆時請遊客見諒。',
  '727507-11': '如遇國家或航空公司政策性調整機票、燃油附加費等價格，請按調整後的價格補足差價。',
  '727507-12': '1人',
  '727507-13': '2人',
  '727507-14': '3人',
  '727507-15': '5人',
  '727507-16': '4人',
  '727507-17': '6人',
  '727507-18': '往返旅遊巴士',
  '727507-19': '去程旅遊巴士',
  '727507-20': '返程旅遊巴士',
  '727507-21': '全程旅遊巴士',
  '727507-22':
    '1.每位遊客都應遵守法律，恪守公德，講究禮儀，愛護環境，尊重旅遊目的地文化習俗。 讓我們攜起手來，從我做起，從現在做起，從點滴做起，共同喊出口號“文明旅遊我先行”。',
  '727507-23':
    '2.中國公民國內旅遊文明行為公約：營造文明、和諧的旅遊環境，關係到每位遊客的切身利益。 做文明遊客是我們大家的義務，請遵守以下公約：',
  '727507-24': '1）維護環境衛生。 不隨地吐痰和口香膠，不亂掉垃圾，不在禁煙場所吸煙。',
  '727507-25': '2）遵守公共秩序。 不喧嘩吵鬧，排隊遵守秩序，不並行擋道，不在公眾場所高聲交談。',
  '727507-26': '3）保護生態環境。 不踐踏草地，不採摘花木和果實，不追捉、投打、亂餵動物。',
  '727507-27': '4）保護文物古蹟。 不在文物古蹟上塗刻，不攀爬觸摸文物，拍照攝像遵守規定。',
  '727507-28':
    '5）愛惜公共設施。 不污損客房用品，不損壞公用設施，不貪占小便宜，節約用水用電，用餐不浪費。',
  '727507-29':
    '6）尊重別人權利。 不强行和外賓合影，不對著別人打噴嚏，不長期占用公共設施，尊重服務人員的勞動，尊重各民族宗教習俗。',
  '727507-30':
    '7）講究以禮待人。 衣著整潔得體，不在公共場所袒胸赤膊； 禮讓老幼殘障，禮讓女士； 不講粗話。',
  '727507-31': '8）提倡健康娛樂。 抵制封建迷信活動，拒絕黃、賭、毒。',
  '727507-32':
    '3.旅途用餐節約倡議“誰知盤中餐，粒粒皆辛苦。”日常的一粥一飯，旅途的一餐一飲，從我做起，珍惜糧食、光碟行動，厲行節約、反對浪費，世界那麼大，祖國那麼美，我們一起享受旅途中的美好“食”光。',
  '727507-33':
    '產品報價按2人入住1間房核算，如要求三人間或加床，需視入住酒店房型及預訂情況而定。通常酒店標準間內加床為鋼絲床或床墊等非標準床。',
  '727507-34':
    '產品報價按2人入住1間房核算，如出現單男單女，儘量安排該客人與其他同性別團友拼房；如不願拼房或未能拼房，請補齊單房差以享用單人房間。',
  '727507-35':
    '產品報價按2人入住1間房核算，如出現單男單女，儘量安排該客人與其他同性團友拼房，如未能拼房者，可選擇與同行親友共用雙人房並加床（加床者按占半房收費，即3人1間）；如不願意與同行親友共用3人房或單人出行者，請補齊單房差以享用單人房間。',
  '727507-36':
    '由於目的地酒店較小，暫不提供3人間，敬請見諒。 房型以到酒店等級時確認為准，不可提前指定房型。',
  '727507-37':
    '由於旅遊旺季或其他特殊原因，凡無法確定準確的住宿場所名稱或酒店等級的，我社均填寫了參攷候選住宿場所名稱。 但所列酒店名稱範圍僅供參考，最終以《出團通知》為准，保證酒店同級。',
  '727507-38':
    '在簽署本旅遊合約時，因特殊原因，對個別無法確定住宿場所名稱、等級的，我社可能會根據旅遊產品的具體情況，注明可以確保的入住場所房間類型，如：雙方標準間； 雙人大床房等。',
  '727507-39': '酒店通常規定12點以前辦理退房手續，下午2點後辦理入住手續，少數酒店或特殊假期除外。',
  '727507-40': '出於環保考慮，所有酒店均不提供一次性衛生用品，請您自行攜帶好相關用品。',
  '727507-41': '旅遊簽證費用',
  '727507-42': '遊學簽證費用',
  '727507-43': '往返機票',
  '727507-44': '單程機票',
  '727507-45': '往返船票',
  '727507-46': '單程船票',
  '727507-47': '接機（站）',
  '727507-48': '往返接送機（站）',
  '727507-49': '送機（站）',
  '727507-50': '去程大巴票',
  '727507-51': '返程大巴票',
  '727507-52':
    '我司將最晚在出行前1天向您發送《出團通知書》，若能提前確定，我們將會第一時間通知您。 如未收到請及時聯系中旅旅行工作人員。',
  '727507-53': '具體情況請見行程推薦/安排',
  '727507-54': '具具體情況請見行程推薦/安排、飛機餐是否收費請參照航空公司規定',
  '727507-55': 'g簽證',
  '727507-56': 'I簽證',
  '727507-57': 'g簽證或I簽證',
  '727507-58':
    '（1）注意交通安全，過馬路請走班馬線，指示燈綠燈亮時才可通過。 注意當地駕駛習慣，如遇靠左行車的國家或地區請注意不要走入逆行道，在過馬路時要向右側觀察是否有來車並及時避讓； 有些城市設有自行車道，行人不可站立或行走在自行車道上。 以上情形下，一旦發生事故，責任須由行人承擔。',
  '727507-59':
    '（2）上下車船和飛機、出入地鐵等交通工具時，出入商場、酒店、餐廳、景區等場所時請注意在電梯、台階處站穩，以免扭傷和摔傷身體。 注意特定安全提示，以免受到傷害。',
  '727507-60':
    '（3）自由活動要結伴而行，尤其是夜間不要單獨出行，不要太晚回酒店； 外出時要告知領隊或導遊，不安全的場所不要去； 要帶好酒店卡片（上有酒店的名稱、地址、電話）和緊急聯繫通訊錄，防止意外情况的發生。',
  '727507-61':
    '（4）遇到地震、颱風、火災、洪水等自然災害或政治動亂、戰亂、突發恐怖事件或意外傷害時，要冷靜處理並盡快撤離危險地區。 在境外可向中國使（領）館尋求諮詢和協助。 人身安全受到威脅和傷害時，應立即向當地警方報案，要求警方提供保護，並取得警方的書面證明。',
  '727507-62':
    '（5）如旅遊者在當地選擇自駕遊或乘坐當地私營交通工具，建議客人審慎選擇真實、合法和安全的租車公司或交通工具，避免由於車輛原因發生交通事故：另外，由於各國基礎設施狀況和交通規則不同，建議遊客提前熟悉當地交通規則、做好路線和路况規劃； 對於一些基礎設施較為落後、自然災害較多的地區和國家，建議遊客提前關注道路和天氣情況，避免疲勞駕駛，繞開風險過高地區，注意人身和財產安全。',
  '727507-63':
    '（6）我公司提醒旅遊者：出境旅遊，特別是海外自助遊應增强風險防範意識，警惕各類風險，平安出遊，理性出遊。',
  '727507-64':
    '應做好行前準備，關注旅遊、外交等有關部門發佈的出行提示，了解目的地天氣、衛生、交通和社會治安情况，謹慎或暫勿前往恐怖事件頻發、政局動盪不穩或發生重大疫情的國家和地區。',
  '727507-65': '出行中旅遊者應關注當地天氣預報和風險提示，避免在不適宜的惡劣天氣開展旅遊活動。',
  '727507-66':
    '要提高安全意識，理性消費，看管好隨身物品，遵守當地交通法規，遇突發事件時保持冷靜，妥善應對，及時報警。',
  '727507-67':
    '合理規劃出遊線路，選擇有運營資質的交通工具，謹慎選擇高風險旅遊項目，不在旅遊探奇中盲目追求刺激。',
  '727507-68': '出行前應購買旅遊意外保險，保障出遊安全。',
  '727507-69': '出於安全考慮，本產品不接受孕婦預訂，敬請諒解！',
  '727507-70':
    '您選購的機票若是團隊機票，機票票號無法提前査詢，同事不能提前辦理值機，具體以出團通知書為准，敬請諒解。',
  '727507-71': '具體航班號及航班時刻，請以最終所出機票信息為准。',
  '727507-72': '最終的航班信息請以出團通知書為准。',
  '727507-73': '經濟艙機票',
  '727507-74': '公務艙機票',
  '727507-75': '頭等艙機票',
  '727507-76': '機票',
  '727507-77':
    '（1）抵達酒店後，請您在前台領取酒店地址名片，外出時隨身攜帶，如果旅遊者迷路時，可以按名片提示的電話、地址安全順利返回酒店。',
  '727507-78':
    '（2）入住酒店後，應了解酒店安全須知，熟悉安全轉移的路線。 入住時立即檢查房間內設施（衛浴設備、房門及窗戶安全鎖、遙控器、煙灰缸、毛巾浴巾等）是否有損壞、缺少、污染。 發現問題請聯系客房服務人員，及時更換調整，以免退房時發生不必要的麻煩。',
  '727507-79': '（3）在洗浴時請注意防滑，建議提前鋪好防滑墊，防止滑倒受傷。',
  '727507-80':
    '（4）睡前及離開酒店房間時請確保關閉房間門窗。 保管好房卡或鑰匙，切勿將房間號告訴陌生人，或邀請陌生人到房間內交談，以免發生人身和財物安全問題。',
  '727507-81':
    '（1）為保證計劃行程順利完成，請遵守團隊作息時間，避免因個人遲到、掉隊影響全團正常行程，請積極配合領隊和導遊的工作。 在景區內請聽從安排，跟隨導遊和領隊遊覽時，不要遠離團隊，注意安全。',
  '727507-82':
    '（2）自由活動期間請結伴而行，避免單獨行動。 非本公司組織安排的遊覽活動，旅遊者自行承擔風險。',
  '727507-83':
    '（3）我公司鄭重提醒旅遊者，謹慎參加有一定危險或高危的娛樂項目，包括但不限於：過山車、駕駛摩托艇、潜水、滑水、滑雪、跳傘、攀岩、探險、賽車、賽馬、騎馬、滑翔、探險性漂流、熱氣球、高空彈跳、衝浪、游泳、出海、泡溫泉、焗桑拿、高空滑索、登山等。 上述項目對參加者的身體狀況有一定要求，請充分了解活動須知，嚴格遵守相關法律和安全規定。 不要因為尋求刺激、逞能或心存僥倖，造成不必要的傷害。 旅遊者應仔細閱讀活動說明和景區提示，慎重選擇及參與活動。 旅遊者因參加危險活動造成人身和財產損失，旅行社概不負責。 酒後禁止參加有一定危險的娛樂項目。',
  '727507-84':
    '（4）在參加上述活動中，由於受到降雨、狂風、颱風、地震、火山噴發等自然環境和天氣變化等不確定因素所影響、或因設備故障或項目方操作及管理不善、安全設施水准不高、缺乏中文安全提示等原因，有可能會造成旅遊者受傷或死亡等安全事故； 同時一旦發生危及生命的事件，由於當地救援條件有限，沒有配備救生人員和教練、資源或者資源有限以及地理位置受限，極易導致無法及時送醫，錯過最佳搶救時間。 我公司建議旅遊者參加上述危險或高危娛樂項目時，投保人身意外傷害保險或帶救援的人身意外傷害保險。',
  '727507-85':
    '（5）海拔較高的高原地帶或山地，氣壓低，空氣含氧量少，易導致人體缺氧，引起高原反應，請旅遊者避免劇烈運動和情緒興奮。 患有貧血、糖尿病、慢性肺病、較嚴重心腦血管疾病、精神病及懷孕等旅遊者不宜參加高原及高山旅遊。',
  '727507-86':
    '（6）為了保證您的旅途安全，也為了方便導遊、領隊及時上下車為遊客提供服務，請讓出汽車的第一排座位。 在旅途中繫好安全帶。 盡量避免在車廂內走動或站立，禁止在車內吸煙。 請勿將頭、手、腳或者行李物品伸出窗外，以免發生意外。 不要向車窗外扔物品，以免傷人。',
  '727507-87':
    '（7）遊覽時，請先聽導遊和領隊的講解和介紹，了解當地的風景民俗，清楚什麼是最有紀念意義的，有利於您進行拍照留念。 每次下車，請先記清集合地點和時間或地標性建築。 記住所乘汽車的牌照號碼、導遊和領隊的姓名及手機號碼。 有的城市在遊覽時需轉乘當地的公交車或鐵路，要注意緊跟隊伍，以免掉隊或走失。',
  '727507-88':
    '（8）雨雪天氣從室外進入酒店、餐廳、遊覽設施時，任何時候出入洗手間時，路面濕滑時，注意防滑。',
  '727507-89':
    '（9）我公司提示旅遊者參加涉水或海上旅遊項目應增强風險防範意識，警惕各類風險，平安出遊，理性出遊，注意以下風險：',
  '727507-90':
    '時刻關注天氣和海况，嚴格遵守惡劣天氣預警及提示，如遇風大浪急等惡劣天氣或海灘插有紅色警示旗，應遵從警示提醒，切勿冒險出海或進行水上項目，以免發生不測，謹記安全第一。',
  '727507-91':
    '參加海上項目，要充分考慮自身水性及身體狀況，根據個人實際情況選擇遊玩項目； 應當熟知海上項目的安全係數、刺激程度，謹慎參加潜水等水上運動，遊玩中務必穿戴好救生衣，聽從專業人士指導，高齡或患有心臟病、高血壓等遊客切勿冒險，未成年人參與水上遊樂項目必須有家長帶領。',
  '727507-92':
    '自由行遊客及選擇行程外涉水或海上旅遊項目和旅遊產品的遊客，應當選擇正規旅行團組，簽訂正式旅遊合約，並重點關注合約中有關人身、財產安全事故的理賠範圍和要求； 出境遊建議出行前購買合適的境外旅行意外保險，謹慎選擇旅行項目。',
  '727507-93':
    '搭乘水上交通工具，務必選擇正規經營、報價合理的承運方，謹防低價陷阱，謹慎選擇旅行項目。',
  '727507-94':
    '（10）如遇緊急情况，請保持鎮定，及時尋找工作人員或報警求助； 境外遇險，可及時報警並向當地領事館或外交部全球領事保護中心尋求幫助。',
  '727507-95': '去程船票',
  '727507-96': '回程船票',
  '727507-97': '全程船票',
  '727507-98':
    '如您在决定旅行的時候已經懷孕，請仔細閱讀以下告知，並慎重决定是否參見此次旅行：\\n1、本產品主要交通數據長距離飛行，班機大多以夜班機或長時間轉機為主，加上出境班機需提前3小時左右赴機場準備安檢事宜，交通接駁較為疲憊。 \\n 2、目的地行程雖較為自由，但也有相當數量的團隊活動； 當地主要活動較為激烈和刺激，請仔細評估您的身體能否適宜。 \\n 3、中旅旅行不建議妊娠期在24周以內、32周以上的孕婦報名。 如您的妊娠期在此之間，需在出發前7天去醫院開具證明，證明內容需體現妊娠時長、醫生判斷您的“適合搭乘飛機”並蓋有醫院紅章，此醫院證明有效期必須在出發前7天內。 如您隱瞞懷孕事宜或開具的證明有效期在7天之外，造成無法登機等一切後果及經濟損失由客人自行承擔',
  '727507-99': '當地機場接機服務費用',
  '727507-100': '當地機場送機服務費用',
  '727507-101': '當地機場接送機服務費用',
  '727507-102': '當地火車站接站服務費用',
  '727507-103': '當地火車站接送站服務費用',
  '727507-104': '當地火車站送站服務費用',
  '727507-105': '當地機場或火車站接機（站）服務費用',
  '727507-106': '當地機場或火車站送機（站）服務費用',
  '727507-107': '當地機場或火車站接送機（站）服務費用',
  '727507-108': '火車票',
  '727507-109': '動車二等座火車票',
  '727507-110': '動車一等座火車票',
  '727507-111': '高鐵二等座火車票',
  '727507-112': '硬座火車票',
  '727507-113': '高鐵一等座火車票',
  '727507-114': '軟座火車票',
  '727507-115': '硬臥火車票',
  '727507-116': '軟臥火車票',
  '727507-117': '行程所列酒店住宿費用',
  '727507-118': '三星級酒店住宿費用',
  '727507-119': '四星級酒店住宿費用',
  '727507-120': '五星級酒店住宿費用',
  '727507-121': '自選酒店住宿費用',
  '727507-122': '二星級酒店住宿費用',
  '727507-123':
    '（1）遊覽時，貴重物品如現金、首飾、銀行卡等要隨身攜帶，最好貼身存放，不要放在挎包裏，也不要放在行李寄存處、旅遊車內或酒店房間； 乘坐飛機、火車和遊船時，貴重物品不能放在行李箱中托運； 入住酒店後，建議將貴重物品存入酒店的保險箱內並設密碼，以免遺失。',
  '727507-124':
    '（2）由於部分國家尤其是歐美國家受經濟低迷、失業率高企、難民湧入等因素影響，接連出現恐怖襲擊事件以及情節惡劣的偷盜、搶劫、詐騙等行為，更有甚者把中國旅遊者視為重點目標。 我公司提請遊客們避免攜帶大量現金，盡量避免單獨外出，同時時刻保持警惕，在餐廳、商場、酒店大堂、機場等公共場所要妥善保管好自己的物品，不要將物品委託不熟悉的人保管，避免發生盜搶、失竊和被騙事件。',
  '727507-125':
    '（3）如果發生行李或貴重物品損壞、遺失、被竊、被搶等，須及時報警、報損、報失，妥善保存報警回執，收集購置遺失物品的原始發票等資料，以備我社協助您向有關方面申請理賠。 但現金、珠寶首飾、信用卡等不予賠付。',
  '727507-126':
    '請您在預訂時務必提供準確、完整的（姓名、性別、證件號碼、國籍、聯繫方式、是否成人或兒童等），以免產生預訂錯誤，影響出行。如因客人提供錯誤個人而造成損失，應由客人自行承擔因此產生的全部損失。',
  '727507-127':
    '根據國家旅遊局（2016年6號）公佈的《旅行社老年旅遊服務規範》行業標準、年滿60周歲（含）以上旅遊者，在報名參加（本產品）旅遊團時，必須填寫《老年人參團申明書》。 《申明書》原件必須和合約一同給到組團社留存歸檔。 請列印簽字後交至工作人員。',
  '727507-128':
    '由於海島旅遊的特殊性，出於安全考慮，請在出行前做一次必要的身體檢查。 患有嚴重心腦血管患者、肺功能不全、孕婦、80周歲以上及行動不便者不建議參加此行程，如需參加請主動申報並提供醫院的身體健康證明，請謹慎報名出行。',
  '727507-129':
    '為確保您能順利出行，下單後，務必提供所有出行人的護照首頁照片或掃描件，届時請及時提供。',
  '727507-130':
    '（1）旅遊期間少吃生食、生海鮮等，不可光顧路邊無牌照小店，忌暴飲暴食，應多喝開水，多吃蔬菜水果，少抽烟，少喝酒。 因私自食用不潔食品和海鮮引起的腸胃疾病，旅行社不承擔責任。',
  '727507-131':
    '（2）在旅遊目的地購買食物需注意商品品質，不要購買“三無”（無生產廠家、生產日期、廠家地址）商品，發現食物不衛生或者存在异味變質的情况，切勿食用。',
  '727507-132': '（3）不要隨意接受和食用陌生人贈送的香煙、食物和飲品，防止不必要的危險。',
  '727507-133':
    '（4）為防止旅途中水土不服，旅遊者應自備一些常用藥品以備不時之需，切勿隨意服用他人提供的藥品。 個人常用藥品請隨身攜帶。',
  '727507-134': '如遇國家政策性調整門票、交通價格等，按調整後的價格補足差額。',
  '727507-135': '行程中贈送的遊覽或娛樂項目，如因班機、天氣等不可抗因素導致未安排，恕不退賠費用。',
  '727507-136': '如遇區域性促銷活動，產生不同預訂城市價格差异，差價不予退還。',
  '727507-137':
    '由於產品報價中所含景點門票按旅行社折扣價核算，除免票條款，遊客不再享受景點門票其它優惠政策。 凡享受免票政策的遊客，須在參團中持相關有效證件，並提前告知導遊，由導遊按旅行社折扣價在旅遊地退費。',
  '727507-138':
    '中旅旅行產品只適用於中國大陸遊客，中國大陸以外有意參團的遊客，在認可行程安排和服務標準的情况下，根據產品要求補齊差價。',
  '727507-139':
    '報價是按照2人入住1間房計算的價格，如您的訂單產生單房，中旅旅行將安排您與其他客人拼房入住。 如您要求享受單房，須補足單房差。',
  '727507-140':
    '報價是按照2人入住1間房計算的價格，如您的訂單產生單房，中旅旅行將安排您與其他客人拼房入住。 在無法拼房的情况下，將會安排3人間加床（溫馨提示：當地酒店面積小，加床可能會引起您的不便，敬請諒解）。 如您要求享受單房，須補足單房差。',
  '727507-141': '此產品不接受3人間加床及拼房，敬請諒解。 如訂單人數為奇數，須補足單房差。',
  '727507-142':
    '報價是按照2成人入住1間房計算的價格，本產品不接受拼房，如您的訂單產生單房，須補足單房差。 由於12歲以下兒童費用為不占床、不含早餐之報價，若兒童需占床含早，須按成人計算並補足單房差。',
  '727507-143':
    '報價是按照2人入住1間房計算的價格，如您的訂單產生單房，中旅旅行將安排您與其他客人拼房入住。 在無法拼房的情况下，將會安排3成人入住一間房，即標準房加床（溫馨提示：當地酒店面積小，加床可能會引起您的不便，敬請諒解）。 如您要求享受單房，須補足單房差。',
  '727507-144':
    '本產品不接受拼房，不提供3人間或加床服務，3成人預訂須支付2間房費。 如需要3成人入住1間雙人標準間，請在預訂時備註清楚，以便客服操作第2間房的單房退款，第3人床位費當地現退，退款金額等同于單房差賣價，第3人不占床不含早，早餐請自理，謝謝配合！',
  '727507-145':
    '本產品接受拼房，但不提供3人間或加床服務，3成人預訂須支付1.5間房費，默認第3人拼房。 如需要3成人入住1間雙人標準間，請在預訂是備註清楚，第3人床位費當地現退賣價，第3人不占床不含早，早餐請自理，謝謝配合！',
  '727507-146':
    '每個房間最多可入住2大1小，如出現2位成人攜帶2位或2位以上兒童出遊的情况需補足2間房的差價。',
  '727507-147':
    '報價是按照2成人入住1間房計算的價格，本產品不接受拼房，如您的訂單產生單房，須補足單房差。 由於12歲以下兒童費用為不占床、不含早餐之報價，若兒童需占床含早，須按成人計算並補足單房差。 每個房間最多可入住2大1小，如2位成人攜帶2位或2位以上兒童出遊的情况需補足2間房的差價。',
  '727507-148':
    '報價是按照2人入住1間房計算的價格，本產品不接受拼房，如您的訂單產生單房，須補足單房差。',
  '727507-149': '“品質之旅”產品，為我司獨立發團產品',
  '727507-150': '“福斯假期”產品，為我司獨立發團產品',
  '727507-151': '“聯合假期”產品，為我司與其他旅行社聯合發團產品',
  '727507-152':
    '“聯合假期”產品，為我司與其他旅行社聯合發團產品。 根據發團需要，可能會在部分行程段發生團友變化，敬請諒解',
  '727507-153':
    '我們非常重視您的意見和建議，《遊客意見表》是我們判定旅遊團服務質量的重要依據，請您如實填寫《遊客意見表》，感謝您留下寶貴的意見和建議，我們將不斷改進工作，更好地為廣大遊客提供服務。',
  '727507-154': '全程不強制購物，當地購物時請慎重考慮，把握好質量與價格，務必索要發票。',
  '727507-155': '遊客在指定購物店中為自願購物，所購商品非品質問題一律不予退換；',
  '727507-156':
    '行程規定的景點、餐廳，長途中途休息站等這類購物店不屬於旅遊定點上點，若商品出現品質問題，旅行社不承擔任何責任。',
  '727507-157': '遊客自行前往的購物店所購商品出現品質問題，旅行社不承擔任何責任',
  '727507-158': '往返',
  '727507-159': '全程',
  '727507-160': '去程',
  '727507-161': '回程',
  '727507-162': '中國大陸地區',
  '727507-163': '中國大陸及港澳臺地區',
  '727507-164':
    '（1）護照、港澳通行證、臺灣通行證是旅遊者在境外唯一有效的身份證明，遺失證照將直接影響到您的旅行。 證照、簽證、身份證、機車船票及文件等身份證明和憑據，請隨身攜帶或把證照交由領隊、導遊妥善保管，以避免遺忘、遺失。',
  '727507-165':
    '（2）出境旅遊最好將上述證件複印，一份留在家中，一份連同幾張護照相片隨身攜帶，並記下所持護照號碼，以備急用。',
  '727507-166':
    '（3）在整個旅行中，請隨身攜帶我公司發給您的行程及緊急聯絡通訊錄（聯繫方式詳見出團通知書），以防走失時聯繫。',
  '727507-167':
    '（4）當地遇到有人檢查證件時，不要輕易應允，而應報告導遊或領隊處理。 如導遊或領隊不在現場，要有禮貌地請對方出示其身份或工作證件，否則拒絕接受檢查。',
  '727507-168':
    '（5）證件一旦遺失或被偷被搶，要立即報告領隊並向警方報案，同時請警方出具書面遺失證明。 出國或出境遊客要向目的地相關機構申請補發簽證，向我使（領）館等機構申請補辦旅行證件，並承擔因補辦證件產生的相關費用。',
  '727507-169':
    '（1）飛機、火車、班輪、城際客運班車等公共客運交通工具延誤或者取消，根據《旅遊法》第七十一條相應責任應由公共交經營者承擔責任，旅行社對此不承擔責任，但將積極協助您向公共交通經營者索賠。 如遇此情况，請旅遊者在候機廳或候車室耐心等待，密切關注班機或火車的信息，聽從工作人員和旅行社人員的安排，不要到處亂跑，以免誤機或誤車。 請勿採取拒絕登機、車、船等不當行為，否則由此造成的損失及後果由旅遊者承擔。',
  '727507-170':
    '（2）交通事故：當發生車輛交通事故時，應聽從導遊及旅行社相關工作人員的安排和指揮，不要慌張； 發生人員傷害時，旅遊者應盡力施救或自救，同時注意保護現場，避免損失擴大。',
  '727507-171':
    '（3）突發意外傷害（如動物傷害）或急性病：旅遊行程中應提高警惕，遠離動物侵襲，避免造成意外傷害。 如不慎遭受意外傷害或因個人原因突發急病時，請及時向導遊或領隊請求援助，到醫院就診，費用由本人支付。 如已購買意外傷害保險，請通知旅行社運作人員並保管好相關票據，以便向保險公司申報理賠。',
  '727507-172':
    '（4）失竊、被搶：當發生財物被盜或遭受搶劫時，請及時聯繫導遊或領隊，請求旅行社協助，並向警察局報案，講明事實經過，將失竊或被搶物品列出清單，保護好現場，保存證據資料，積極配合警察局偵查破案。',
  '727507-173':
    '（5）不可抗力：行程中因自然災害、惡劣天氣條件、政府行為、社會異常事件（如罷工、政變、騷亂、遊行等）、流行性疾病暴發等不可抗力或意外事件，影響旅遊行程或服務標準的，旅行社不承擔賠償責任，但會以確保團隊安全、減少損失為原則，採取積極補救措施，請旅遊者給予配合和諒解， 冷靜處理並盡快撤離危險地區。 在境外可向中國使（領）館尋求諮詢和協助。 由此新增的費用由旅遊者承擔，節省的費用退還旅遊者。',
  '727507-174': '個簽',
  '727507-175': '團簽',
  '727507-176': '落地簽',
  '727507-177': '航空公司',
  '727507-178': '三峽郵輪公司和三峽大壩景區',
  '727507-179': '酒店',
  '727507-180': '三峽郵輪公司',
  '727507-181': '三峽大壩景區',
  '219488-0': '關閉',
  '219488-1': '關聯產品',
  '219488-4': '產品負責人',
  '219488-5': '上下架狀態',
  '430935-0': '資源基本信息',
  '430935-1': '行程信息',
  '430935-2': '資源推薦',
  '430935-3': '費用說明',
  '430935-4': '須知&說明',
  '430935-5': '交通搭配設定',
  '824104-0': '供應商產品信息',
  '824104-1': '產品名稱',
  '824104-2': '產品描述',
  '824104-3': '更多說明',
  '824104-4': '產品的加購類型：',
  '824104-5': '產品所屬區域：',
  '824104-6': '退款規則：',
  '824104-7': '已支付訂單的是否可修改：',
  '824104-8': '是',
  '824104-9': '否',
  '824104-10': '已支付訂單的是否可取消：',
  '824104-11': '取消訂單提前天數：',
  '824104-12': '取消訂單結束時間：',
  '824104-13': '產品允許售賣日期：',
  '824104-14': '產品停止售賣日期：',
  '824104-15': '產品中的酒店信息',
  '824104-16': '酒店代碼',
  '824104-17': '酒店名稱',
  '824104-18': '房床型編號',
  '824104-19': '房床型名稱',
  '824104-20': '數量',
  '824104-21': '產品中的門票信息',
  '824104-22': '門票編號',
  '824104-23': '門票名稱',
  '824104-24': '產品中的餐廳信息',
  '824104-25': '酒店餐廳代碼',
  '824104-26': '酒店餐廳名稱',
  '824104-27': '餐飲類型',
  '824104-28': '餐廳時段',
  '824104-29': '使用時間',
  '824104-30': '餐飲數量',
  '824104-31': '早餐',
  '824104-32': '午餐',
  '824104-33': '晚餐',
  '824104-34': '早午茶',
  '824104-35': '下午茶',
  '824104-36': '其他',
  '824104-37': '首日餐飲',
  '824104-38': '每日餐飲',
  '824104-39': '非加購',
  '824104-40': '加購',
  '824104-41': '全部',
  '090056-0': '供應商打包產品信息',
  '090056-1': '產品編碼',
  '090056-2': '產品描述',
  '090056-3': '產品名稱',
  '090056-4': '退款規則',
  '090056-5': '產品停止售賣日期',
  '090056-6': '產品允許售賣日期',
  '090056-7': '打包產品類型',
  '090056-8': '酒店間夜數',
  '090056-9': '供應商打包品種的酒店信息',
  '090056-10': '酒店code',
  '090056-11': '酒店名稱',
  '090056-12': '物理房型',
  '090056-13': '數量',
  '090056-14': '價格計劃code',
  '090056-15': '供應商打包品種的門票信息',
  '090056-16': '門票名稱',
  '090056-17': '門票產品code',
  '090056-18': '門票數量',
  '090056-19': '門票類別名稱',
  '090056-20': '成人',
  '090056-21': '兒童',
  '090056-22': '老人',
  '090056-23': '不確認',
  '341238-0': '費用包含',
  '341238-1': '兒童價標準',
  '341238-2': '年齡',
  '341238-3': '輸入數字',
  '341238-4': '周歲（含），',
  '341238-5': '選填，請說明兒童服務標準。',
  '341238-6': '身高',
  '341238-7': '米（含），',
  '341238-8': '兒童價特殊說明',
  '341238-9': '可在這裡說明具體的兒童價特殊說明信息',
  '341238-10': '如有說明信息可在此維護',
  '341238-11': '費用不包含',
  '357343-1': '是否可搭配大交通',
  '357343-2': '是',
  '357343-3': '否',
  '357343-4': '最晚抵達時間',
  '357343-5': '請選擇時間',
  '357343-6': '最早返回時間',
  '357343-7': '請選擇是否可以搭配大交通',
  '357343-8': '保存成功',
  '676189-0': '內容不能為空',
  '676189-2': '已選的內容描述不能為空',
  '676189-3': '必選',
  '676189-4': '首站目的地城市',
  '676189-5': '遊玩城市',
  '676189-6': '入住城市',
  '676189-7': '目的地城市',
  '676286-0': '庫存看板',
  '676286-1': '提交審批',
  '676286-2': '您確定提交審批該資源嗎',
  '676286-3': '確定提交',
  '494444-0': '備註',
  '494444-1': '資源狀態',
  '494444-4': '操作記錄',
  '494444-6': '操作項',
  '494444-7': '操作時間',
  '494444-8': '操作人',
  '494444-9': '操作賬號',
  '484689-0': '編輯行程',
  '239540-0': '今日介紹',
  '239540-1': '活動',
  '239540-2': '步驟1',
  '239540-3': '約',
  '239540-4': '開始',
  '239540-5': '小時',
  '239540-6': '分',
  '239540-7': '溫馨提示：',
  '239540-8': '活動說明：',
  '239540-9': '交通說明：',
  '239540-10': '項目介紹：',
  '239540-11': '餐食',
  '239540-12': '入住',
  '239540-13': '備註：',
  '239540-14': '溫馨提示',
  '239540-15': '或',
  '239540-16': '【外觀】',
  '008832-0': '景點',
  '008832-2': '自費項目',
  '008832-3': '購物店',
  '008832-4': '行程概覽',
  '273619-0': '首選圖片',
  '273619-1': '上傳圖片',
  '273619-2': '選擇POI圖片',
  '273619-3': '請選擇行程相關的圖片，圖片尺寸不小於375*300像素，大小不超過10M，數量不超過10張。',
  '273619-4': '請在此處描述套餐內容、預約信息等，內容將帶入對客的單項委託協定中。',
  '273619-5': '查看示例',
  '273619-6': '示例：融創海世界親子套餐',
  '273619-7': '套餐內容：',
  '273619-8': '高級客房*1晚（48㎡，大/雙床可選）',
  '273619-9': '2大1小自助早餐*1次',
  '273619-10': '次日融創樂園/海世界門票2張',
  '273619-11': '預約信息',
  '273619-12':
    '1、因疫情防控需要，酒店部分娛樂設施，如室內兒童樂園，桑拿等開放時間以酒店當天公佈為準。',
  '273619-13': '2、雪世界套餐 節假日遊玩 前台補差150元/晚。',
  '273619-14':
    '3、以上所有包含於房價或套餐內的項目，如和人未選擇使用，視為放棄，酒店不以任何形式進行補償。',
  '273619-15': '請簡要描述行程特色，字數控制在50字以內',
  '273619-16': '新增',
  '273619-17': '删除',
  '273619-18': '請選擇行程相關的圖片，圖片尺寸寬度不小於750像素，大小不超過10M，數量不超過10張。',
  '273619-19': '請上傳圖片',
  '273619-20': '請上傳不超過10張圖片',
  '273619-21': '請描述資源推薦',
  '273619-22': '請填寫套餐說明',
  '273619-23': '請簡要描述行程特色',
  '273619-24': '最多添加10條',
  '273619-25': '資源特色',
  '273619-27': '產品特色',
  '273619-28': '產品賣點',
  '273619-29': '請上傳不小於375*300點數的圖片',
  '216934-0': '預訂限制',
  '216934-1': '本產品預訂時需提供有效證件',
  '216934-3': '年齡限制',
  '216934-4': '本產品不接受',
  '216934-6': '歲以上（含）客人預訂，敬請諒解。',
  '216934-7': '歲以下（不含）客人預訂，敬請諒解。',
  '216934-8': '本產品適用',
  '216934-9': '最低數字',
  '216934-10': '歲（含）-',
  '216934-11': '最高數字',
  '216934-12': '歲（含）的客人預訂，敬請諒解',
  '216934-13': '違約條款',
  '216934-13-tips': '違約條款將帶入合約中，請確保填寫的內容準確',
  '216934-14': '旅行者在行程開始前',
  '216934-15':
    '日以內提出解除合約或者按照本合約第十二條第2款約定由旅行社在行程開始前解除合約的，按下列標準扣除必要的費用：',
  '216934-16': '在行程開始前',
  '216934-17': '日（含）至',
  '216934-18': '日（含），按旅遊費用總額的',
  '216934-21': '行程開始當日，按旅遊費用的',
  '216934-22': '如需有說明信息可在此維護',
  '216934-23': '預訂須知',
  '216934-24': '預訂說明',
  '216934-26': '在此輸入溫馨提示',
  '216934-27': '安全須知',
  '216934-28': '特別提示',
  '216934-29': '如有補充請在這裡說明',
  '424396-0': '服務標準同成人。',
  '424396-3': '費用說明不能為空',
  '424396-4': '大交通',
  '424396-5': '住宿',
  '424396-7': '隨團服務人員',
  '424396-8': '地面交通',
  '424396-9': '門票及地面項目',
  '424396-10': '簽證/簽注',
  '424396-13': '旅遊意外險',
  '424396-14': '離境稅',
  '424396-15': '簽證費用',
  '424396-16': '兒童附加費',
  '424396-17': '補充',
  '424396-18': '請輸入5位數以內的數允許兩位小數',
  '424396-19': '請輸入國家簡稱',
  '424396-20': '請輸入年齡',
  '424396-21': '請輸入0~18（不含）以內的整數',
  '424396-22': '為0~2以內的以內的數字，允許兩位小數',
  '424396-23': '請輸入身高',
  '424396-24': '為0~2以內的以內的數字，允許兩位小數',
  '424396-25': '請輸入身高價標準維護錯誤',
  '424396-26': '請輸入幣種',
  '424396-27': '請輸入國家-金額',
  '424396-28': '請輸入1位非零整數',
  '424396-29': '請輸入稅的名稱',
  '424396-30': '請輸入航班號',
  '424396-31': '請輸入住宿目的地',
  '424396-32': '請輸入具體內容',
  '424396-33': '請輸入景區名稱',
  '424396-34': '請輸入出發地',
  '424396-35': '請輸入目的地',
  '424396-36': '請輸入景區名稱+具體小景點',
  '424396-37': '請輸入景區名稱+小交通/纜車/講解費用/遊船等項目費用',
  '424396-38': '請輸入小交通/纜車等',
  '424396-39': '請輸入相關人員',
  '424396-40': '請輸入費用+貨幣',
}
