export default {
  pleaseChoose: '請選擇',
  pleaseEnter: '請輸入',
  number: '數字',
  customFieldTitle: '自定義展示列',
  rules: {
    integerGreaterThanOrEqualToZero: '≥0的整數',
    zero99Digits: '0-99數字',
    zero59Digits: '0~59的數字',
    bigZeroInteger: '>0的整數',
    largeEtcZeroInteger: '≥0的數',
    imgTips: '請上傳格式為{0}，尺寸為{1}*{2}，大小{3}的圖片',
    normalImgTips: '請上傳格式為JPG、JPEG、PNG，尺寸為{1}*{2}，大小200k以內的圖片',
    addLimitLen: '最多添加{0}條數據',
    pleaseUploadImg: '請上傳圖片',
    天数: '天數',
  },
  field: {
    comma: '、',
    colon: '：',
    lt: '<',
    gt: '>',
    bracketsL: '【',
    bracketsR: '】',
    day: '天',
    updateTime: '更新時間',
    operate: '操作',
    operationRecord: '操作記錄',
    operationUser: '操作人',
    operationTime: '操作時間',
    operationAction: '操作項',
    operationAccount: '操作賬號',
    operationContent: '操作內容',
    remark: '備註',
    yuan: '元',
    set: '套',
    person: '人',
    folio: '張',
    part: '份',
    large: '大',
    small: '小',
    baby: '嬰',
    items: '項',
    departureCityId: '出發地',
    destinationCity: '目的地',
    choosed: '已選',
    success: '成功',
    star: '*',
    page: '頁面',
    yep: '是',
    nope: '否',
  },
  data: {
    loading: '加載中',
    noData: '暫無數據',
    index: '序號',
    status: '狀態',
  },
  button: {
    detail: '詳情',
    look: '查看',
    confirm: '確定',
    cancel: '取消',
    save: '保存|保存並新增',
    tips: '提示',
    expand: '展開',
    putAway: '收起',
    inquire: '査詢',
    new: '新增',
    delete: '删除',
    sumText: '合計',
    reset: '重置',
    all: '全部',
    return: '返回',
    closure: '關閉',
    edit: '編輯',
    deactivate: '停用',
    enable: '啟用',
    abandon: '廢棄|作廢',
    confirmAbandon: '確認廢棄',
    selectAll: '全選',
    log: '操作記錄',
    download: '下載',
    copy: '複製',
    onConfirm: '確認',
    submit: '提交',
    putSlot: '上架',
    soldOut: '下架',
    preview: '預覽',
    audit: '審核',
    upLoadFile: '上傳文件',
    apply: '申請',
    uploadImg: '上傳圖片',
    passed: '通過',
    update: '更新',
    approve: '審核',
    handle: '處理',
    test: '測試',
    print: '列印',
    add: '添加',
    more: '更多',
    send: '發送',
    refresh: '刷新',
    toHandle: '去處理',
    revoked: '撤銷',
  },
  result: {
    passed: '已通過',
    deactivate: '已停用',
  },
  messageInfo: {
    deleteSuccess: '删除成功',
    abandonSuccess: '作廢成功',
    saveSuccess: '保存成功',
    confirmSuccess: '確認成功',
    returnSuccess: '退回成功',
    auditSuccess: '審核成功',
    carrySuccess: '結轉成功',
  },
  date: {
    year: '年',
    month: '月',
    day: '日|天',
    night: '晚',
    week: '周',
    today: '今天',
    hour: '小時|時',
    minute: '分鐘|分',
    second: '秒',
    selectDate: '請選擇日期',
    selectTime: '請選擇時間',
    startDate: '開始日期',
    startTime: '開始時間',
    endDate: '結束日期',
    endTime: '結束時間',
    departDate: '出發日期',
    startMonth: '開始月份',
    endMonth: '結束月份',
    to: '至',
    weeks: {
      sun: '日',
      mon: '一',
      tue: '二',
      wed: '三',
      thu: '四',
      fri: '五',
      sat: '六',
    },
    months: {
      jan: '一',
      feb: '二',
      mar: '三',
      apr: '四',
      may: '五',
      jun: '六',
      jul: '七',
      aug: '八',
      sep: '九',
      oct: '十',
      nov: '十一',
      dec: '十二',
    },
    startToEndTime: '開始-結束時間',
  },
  placeholder: {
    departureCityId: '請選擇出發地',
    startTimeAndEndTime: '請選擇開始時間和結束時間',
  },
  city: {
    nationwide: '全國',
    partial: '部分',
  },
  units: {
    k: 'k',
    normalImgType: 'JPG、JPEG、PNG',
  },
  PreviewH5: {
    title: '預覽產品',
  },
  PublicOperationLog: {
    content: '操作備註',
    serviceId: '審核編號',
  },
  AllCategory: {
    placeholder: {
      one: '一級品類',
      two: '二級品類',
      three: '目的地大類',
      four: '一級目的地',
      five: '二級目的地',
      six: '目的地名稱',
    },
    PleaseSelectTheSuperiorCategoryFirst: '請先選擇上級品類',
    PleaseSelectTheParentDestinationFirst: '請先選擇上級目的地',
  },
  touristType: {
    adult: '成人',
    child: '兒童',
    baby: '嬰兒',
    adultColon: '成人：',
    childColon: '兒童：',
  },
  certType: {
    identityCard: '身份證',
    passport: '護照',
    inPassport: '國際護照',
  },
  sex: {
    woman: '女',
    man: '男',
  },
  nation: {
    china: '中國',
  },
  文件预览: '文件預覽',
}
