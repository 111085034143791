export default {
  issueTickets:
    '交易已生成，所有資源均占位成功、附加項均審核通過、完成全額收款，您可直接點擊【去出票】。',
  '733977-0':
    '交易已生成，產品會給客人預留位子，請您在#內跟催客人完成支付；如超期未完成，預留位子會被釋放。',
  '733977-1':
    '交易已生成，您可以根據客人意願調整預訂產品及資源，資源占位後會預留位子，如客人意向明確請先占位。',
  '733977-2': '資源占位反饋中，請儘快跟催產品負責人反饋占位結果。',
  '733977-3': '資源占位失敗，您可以跟客人溝通，重新更換其他產品或資源預訂。',
  '733977-4': '當前待客人簽署合約，您可以跟催客人完成合約簽署。',
  '733977-5':
    '當前待客人支付，您可以跟催客人完成支付，如客人線下支付，您需要將客人收款信息及時錄入系統。',
  '733977-6': '資源確認反饋中，請儘快跟催產品負責人反饋確認結果。',
  '733977-7': '資源確認失敗，請儘快跟產品負責人溝通解決方案，並將解決方案提供給客人選擇。',
  '733977-8':
    '請儘快跟催產品負責人製作出遊通知，製作完成後，您可將出遊通知發送給客人； 如無需發送出遊通知，可直接點擊【完成出遊通知發送】。',
  '733977-9': '出遊通知發送失敗，請檢查聯絡方式是否正確，聯絡方式核對無誤後，可直接點擊【重發】。',
  '733977-10': '已完成資源確認，等待客人出遊，如有預訂項目變化，可通過附加項調整。',
  '733977-11': '已完成資源確認，如客人有需要，可【列印】【發送】#給客人。',
  '733977-12': '資源已發起取消申請，正在核實損失，請儘快跟催產品負責人反饋損失情況。',
  '733977-13':
    '產品負責人已反饋損失情況，您可【確認核損結果】，並儘快跟客人溝通損失情況，確定是否最終取消。',
  '733977-14': '資源取消中，請儘快跟催產品負責人完成資源取消反饋結果。',
  '733977-15': '您需發起退款申請，並跟催財務完成對客退款。',
  '733977-16': '資源取消確認失敗，請儘快跟產品負責人溝通解決方案，並將解決方案提供給客人選擇。',
  '733977-17': '資源已發起取消申請，正在核實損失，請儘快跟催產品負責人反饋損失情況。',
  '733977-18':
    '產品負責人已反饋損失情況，您可【確認核損結果】，並儘快跟客人溝通損失情況，確定是否最終取消。',
  '733977-19': '客人出遊中，如出遊中有預訂項目變化，可通過附加項調整。',
  '733977-20': '客人出遊歸來，如出遊中有預訂項目變化，可通過附加項調整。',
  '733977-21': '交易已取消。',
  '733977-22': '交易已取消，您需發起退款申請，並跟催財務完成對客退款。',
}
